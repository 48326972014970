var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "link-task-wrap" },
    [
      _c("div", { staticClass: "search-title" }, [
        _c("div", [
          _vm._v("\n      Link Task to Feature or User Story\n      "),
          _c("img", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.linkSpinner,
                expression: "linkSpinner",
              },
            ],
            staticClass: "button-spinner",
            staticStyle: { float: "right" },
            attrs: { src: "/img/lightbox/preloader.gif" },
          }),
        ]),
        _c("input", {
          directives: [
            {
              name: "model",
              rawName: "v-model",
              value: _vm.searchBox,
              expression: "searchBox",
            },
          ],
          staticClass: "feature-search-box",
          attrs: { type: "text" },
          domProps: { value: _vm.searchBox },
          on: {
            input: function ($event) {
              if ($event.target.composing) {
                return
              }
              _vm.searchBox = $event.target.value
            },
          },
        }),
      ]),
      _vm._l(_vm.filterFeature, function (feature, i) {
        return feature.feature !== " "
          ? _c(
              "div",
              {
                key: i,
                staticClass: "user-story-list",
                on: {
                  click: function ($event) {
                    return _vm.handleLinkTaskToFeature(feature.id)
                  },
                },
              },
              [
                _vm._v("\n    " + _vm._s(feature.feature) + "\n    "),
                _vm.currentTask.feature_id == feature.id
                  ? _c("img", {
                      staticStyle: { float: "right" },
                      attrs: { src: "/img/dashboard/task/checkmark.svg" },
                    })
                  : _vm._e(),
              ]
            )
          : _vm._e()
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }