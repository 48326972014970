<template>
  <div>
    <div
      v-if="features.length == 0 && startRoad == false"
      class="empty-roadmap"
    >
      <div>
        <img src="/img/dashboard/empty-roadmap.svg" />
      </div>
      <div class="empty-state">There’s nothing here yet.</div>
      <div class="roadmap-bold">Product Roadmapping</div>
      <div class="roadmapping-info">
        1.
        <span>
          Start by putting the most important Product Features or User Stories
          in Iteration 1</span
        >
      </div>
      <div class="roadmapping-info">2. <span>Add other iterations</span></div>
      <div class="roadmapping-info">
        3.
        <span
          >Plan the Sprints for
          <span style="font-weight: bold">Iteration 1</span></span
        >
      </div>
      <div class="roadmapping-info">
        4. <span>Share Roadmap with Product Owner</span>
      </div>
      <button
        class="grupa-blue-btn"
        style="margin-top: 48px"
        @click="startRoad = true"
      >
        Get Started
      </button>
    </div>
    <div v-else>
      <VuePerfectScrollbar class="perfect-scroll-area" :settings="settings">
        <div class="space-wrapper" id="it-cont">
          <div
            class="iteration-card"
            v-for="(iteration, index) in iterations"
            :key="index"
            :class="{
              'current-iteration':
                iteration.id == project.current_iteration &&
                project.workspace_progress_level >= 7,
            }"
          >
            <div
              v-if="
                project.workspace_progress_level >= 7 &&
                iteration.id == project.current_iteration
              "
              class="lock-iteration"
            >
              <img src="/img/dashboard/green-lock.svg" alt="" />
            </div>
            <div
              class="iteration-info"
              @mouseover="handleItrOver(iteration.id)"
              @mouseleave="
                itrOver = false;
                iterationHoverId = '';
              "
            >
              <div class="iteration-name">
                <img :src="`/img/dashboard/iteration${index + 1}.svg`" />
                Iteration {{ index + 1 }} -
                <span>{{ `${iteration.name}’s` }}</span>
              </div>
              <div
                v-if="iterationHoverId == iteration.id && itrOver"
                class="delete-itr-div"
                @click="handleDeletePrompt(iteration)"
              >
                Delete
              </div>
              <div v-else class="iteration-duration">
                {{ project.iteration_cycle }}wks
              </div>
            </div>
            <!-- v-if="
                isAddFeature(
                  project.workspace_progress_level,
                  project.current_iteration,
                  iteration.id
                )
              " -->
            <div class="add-new-feature">
              <textarea
                v-model="featuresObj[`newFeature${iteration.id}`]"
                placeholder="+ Add Product Feature or User story"
                class="text-area-style"
                :id="iteration.id"
                @keydown="keydownFeature($event)"
                @blur="handleAddFeature($event, iteration.id)"
                style="font-size: 14px"
              >
              </textarea>
            </div>
            <div
              class="divider-line"
              :style="
                isAddFeature(
                  project.workspace_progress_level,
                  project.current_iteration,
                  iteration.id
                ) == false
                  ? 'margin-top: 16px'
                  : ''
              "
            ></div>
            <VuePerfectScrollbar
              class="perfect-scroll-area"
              :settings="settings"
            >
              <div
                class="features-wrapper"
                :class="{
                  'locked-itearation': iteration.status > 0,
                  'unlocked-iteration': iteration.status == 0,
                }"
              >
                <Container
                  group-name="col"
                  drag-class="card-ghost"
                  drop-class="card-ghost-drop"
                  :drop-placeholder="dropPlaceholderOptions"
                  @drop="(e) => onCardDrop(iteration.id, e)"
                  :get-child-payload="getCardPayload(iteration.id)"
                >
                  <Draggable v-for="(feature, k) in features" :key="k">
                    <div
                      v-if="feature.iteration_id == iteration.id"
                      class="feature-card"
                      @mousedown="currentFeature = feature.id"
                      @click="handleDrawer(feature, index)"
                    >
                      <div>{{ feature.feature }}</div>
                      <div class="feature-icons">
                        <div class="feature-chat">
                          <img src="/img/dashboard/message-icon.svg" /> 0
                        </div>
                        <div class="number-of-sprint">
                          <img src="/img/dashboard/sprint-icon.svg" />
                          {{ feature.tasks.length }}
                        </div>
                        <!--  <span> {{ JSON.stringify(feature.id) }}</span> -->
                        <div class="feature-upload">
                          <img src="/img/dashboard/attach.svg" /> 0
                        </div>
                      </div>
                    </div>
                  </Draggable>
                </Container>
              </div>
            </VuePerfectScrollbar>
          </div>
          <div class="add-iteration">
            <div v-show="!iterationSpinner" @click="addNewIteration">
              + Add Iteration
            </div>
            <div v-show="iterationSpinner">
              <img
                src="/img/lightbox/preloader.gif"
                style="margin-left: 20px"
                class="button-spinner"
              />
            </div>
          </div>
        </div>
      </VuePerfectScrollbar>
    </div>
    <FeatureDrawer
      ref="drawer"
      v-bind="{
        selectedFeature,
        updateFeature,
        iterationNum,
        closeFeatureOverLay,
      }"
    />
    <DeleteItrModal
      v-if="isDeleteItr"
      v-bind="{
        closeDeleteItr,
        deleteIteration,
        delSpinner,
        handleDeleteIteration,
      }"
    />
  </div>
</template>

<script>
// import Pusher from "pusher-js";
import VuePerfectScrollbar from "vue-perfect-scrollbar";
import { Container, Draggable } from "vue-smooth-dnd";
import FeatureDrawer from "./featureDrawer";
import DeleteItrModal from "./deleteItrModal";
import { mapActions, mapState } from "vuex";
import {
  devCreateIteration,
  devCreateFeature,
  devDeleteIteration,
  moveProjectFeature,
} from "@/api";
import { devUpdateFeature, getFeatureComment } from "@/api/team";

export default {
  components: {
    Container,
    Draggable,
    FeatureDrawer,
    DeleteItrModal,
    VuePerfectScrollbar,
  },

  data: () => ({
    settings: {
      maxScrollbarLength: 60,
    },
    startRoad: false,
    selectedFeature: {},
    iterationNum: 0,
    featuresObj: "",
    versionNames: [
      "Must Have",
      "Need to Have",
      "Should Have",
      "Could Have",
      "Nice to Have",
      "May Have",
      "Wish List",
    ],
    iterationHoverId: "",
    itrOver: false,
    isDeleteItr: false,
    deleteIteration: {},
    delSpinner: false,
    dropPlaceholderOptions: {
      className: "drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    currentFeature: 0,
    iterationSpinner: false,
  }),

  created() {
    this.generateFeatureForItr();
    // this.realTimeComments();
    // Pusher.logToConsole = false;
    /*  var pusher = new Pusher(process.env.VUE_APP_PUSHER_APP_ID, {
      cluster: "mt1",
      forceTLS: true,
    }); */
    let id;
    if (this.selectedFeature && this.selectedFeature.id) {
      id = this.selectedFeature.id;
    } else {
      id = 694;
    }
    // var channel = pusher.subscribe("project_feature_chat");
    // channel.bind(`project_feature_${id}`, (data) => { });
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("devDashboard", [
      "updateSelectedProjectDoc",
      "updateProjectFeatures",
      "setFeatureComments",
      "updateProjectIterations",
    ]),
    generateFeatureForItr() {
      let obj = {};
      this.iterations.map((itr) => {
        obj[`newFeature${itr.id}`] = "";
      });
      this.featuresObj = obj;
    },
    handleDrawer(feature, iterationNum) {
      console.log(feature)
      let { id } = feature;
      this.fetchComment(id);
      this.selectedFeature = JSON.parse(JSON.stringify(feature));
      this.iterationNum = iterationNum;
      this.$refs.drawer.toggleDrawer();
    },
    closeFeatureOverLay() {
      this.$refs.drawer.toggleDrawer();
    },
    keydownFeature(event) {
      if (event.key != "Enter") {
        return;
      }
      event.target.blur();
    },
    addNewIteration() {
      this.iterationSpinner = true;
      const data = {
        name: this.versionNames[this.iterations.length],
        project_id: parseInt(this.$route.params.id, 10),
      };
      devCreateIteration(data)
        .then((response) => {
          if (response.data.status == 1) {
            this.updateSelectedProjectDoc(response.data.data);
            if (this.iterations.length > 4) {
              setTimeout(() => {
                let iterationContainer = document.getElementById("it-cont");
                iterationContainer.scrollLeft += 100000;
              }, 500);
            }
          }
        })
        .catch((error) => {})
        .finally(() => {
          this.iterationSpinner = false;
        });
    },
    updateFeature(event, type = "feature") {
      event.preventDefault();
      let el = event.target;
      let { id, feature, iteration_id, description } = this.selectedFeature;
      let payload = {
        iteration_id,
        feature_id: id,
      };
      if (
        (feature !== "" && type == "feature") ||
        (description !== "" && type == "description")
      ) {
        console.log(description, feature);
        el.classList.add("textShine");
        if (type == "feature") {
          payload.feature = feature;
        }
        if (type == "description") {
          payload.description = description;
        }
        devUpdateFeature(payload)
          .then((res) => {
            if (res.data.status == 1) {
              this.updateProjectFeatures(res.data.data.features);
              el.classList.remove("textShine");
            }
          })
          .catch((err) => {
            el.classList.remove("textShine");
            this.setErrorMsg("Please try again!!");
          });
      }
    },
    handleAddFeature(event, iterationId) {
      event.preventDefault();
      let data;
      let el = event.target;
      if (this.featuresObj[`newFeature${iterationId}`] !== "") {
        el.classList.add("textShine");
        data = {
          feature: this.featuresObj[`newFeature${iterationId}`],
          iteration_id: iterationId,
          project_id: this.$route.params.id,
        };
        devCreateFeature(data)
          .then((res) => {
            this.$developConsole(res, "milestone created");
            this.updateProjectFeatures(res.data.data.features);
            el.classList.remove("textShine");
            this.featuresObj[`newFeature${iterationId}`] = "";
          })
          .catch((err) => {
            this.$developConsole(err);
          });
      }
    },
    fetchComment(id) {
      this.setFeatureComments([]);
      getFeatureComment(id)
        .then((resp) => {
          this.setFeatureComments(resp.data.data.reverse());
        })
        .catch((error) => {
          console.log(error, "error resp...");
        });
    },
    realTimeComments() {},
    handleItrOver(id) {
      this.iterationHoverId = id;
      this.itrOver = true;
    },
    handleDeletePrompt(itr) {
      this.deleteIteration = itr;
      this.isDeleteItr = true;
    },
    closeDeleteItr() {
      this.isDeleteItr = false;
    },
    handleDeleteIteration(id) {
      this.delSpinner = true;
      devDeleteIteration(id)
        .then((res) => {
          if (res.data.status == 1) {
            this.setSuccessMsg("Iteration deleted sucessfully");
            this.isDeleteItr = false;
            this.delSpinner = false;
            let newIterations = this.iterations.filter((el) => el.id != id);
            this.updateProjectIterations(newIterations);
          } else {
            this.isDeleteItr = false;
            this.delSpinner = false;
            this.setErrorMsg(res.data.msg);
          }
        })
        .catch((err) => {
          this.isDeleteItr = false;
          this.delSpinner = false;
          if (err.response.data.status == 0) {
            this.setErrorMsg(err.response.data.msg);
          } else {
            this.setErrorMsg("Something wierd happened, please try again");
          }
        });
    },
    onCardDrop(columnId, dropResult) {
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        // if (dropResult.removedIndex !== null) {
        //   console.log(dropResult.removedIndex, columnId, "source");
        // }
        if (dropResult.addedIndex !== null) {
          let payload = {
            iterationId: columnId,
            position: dropResult.addedIndex,
            featureId: dropResult.payload.id,
            movedFeature: dropResult.payload,
          };
          let newFeature = {
            ...payload.movedFeature,
            iteration_id: payload.iterationId,
          };
          console.log(
            `${dropResult.addedIndex} position`,
            `${columnId} itr id`,
            `${newFeature.id} ftr id`
          );
          let featuresArray = JSON.parse(JSON.stringify(this.features));
          let featureIndex = featuresArray.findIndex(
            (feature) => feature.id == payload.featureId
          );
          featuresArray[featureIndex] = newFeature;
          this.updateProjectFeatures(featuresArray);
          const payloadObj = {
            feature_id: newFeature.id,
            new_position: dropResult.addedIndex,
            iteration_id: columnId,
          };
          this.handleMoveFeature(payloadObj);
        }
      }
    },
    getCardPayload(columnId) {
      return (index) => {
        return this.features.filter((p) => p.id === this.currentFeature)[0];
      };
    },
    isAddFeature(progressLevel, currentItr, itrId) {
      if (progressLevel >= 7 && currentItr == itrId) {
        return false;
      } else {
        return true;
      }
    },
    async handleMoveFeature(payload) {
      await moveProjectFeature(payload)
        .then((resp) => {
          this.setSuccessMsg("Feature moved!!!");
        })
        .catch((err) => {});
    },
  },
  computed: {
    ...mapState("devDashboard", [
      "selectedProjectDoc",
      "selectedProject",
      "teamInfo",
    ]),
    iterations() {
      return this.selectedProjectDoc.iterations;
    },
    features() {
      return this.selectedProjectDoc.features;
    },
    project() {
      return this.selectedProject;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

.feature-upload,
.feature-chat {
  display: none;
}
.unlocked-iteration {
  height: calc(100vh - 320px);
}

.locked-itearation {
  height: calc(100vh - 230px);
}

.roadmap-bold {
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  text-align: center;
  color: #1b1e22;
  margin-top: 48px;
}

.delete-itr-div {
  margin-top: 4px;
  font-weight: bold;
  font-size: 12px;
  line-height: 130%;
  color: #d45b54;
}

.card-column-header {
  font-size: 18px;
}

.column-drag-handle {
  cursor: move;
  padding: 5px;
}

.card-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(5deg);
}

.card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg);
}

.opacity-ghost {
  transition: all 0.18s ease;
  opacity: 0.8;
  /* transform: rotateZ(5deg); */
  background-color: cornflowerblue;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0.3);
}

.opacity-ghost-drop {
  opacity: 1;
  /* transform: rotateZ(0deg); */
  background-color: white;
  box-shadow: 3px 3px 10px 3px rgba(0, 0, 0, 0);
}

.feature-card {
  cursor: move;
}

.iteration-card {
  margin-right: 8px;
  padding: 12px 12px 12px 12px;
  position: relative;
}

.lock-iteration {
  position: absolute;
  left: -13px;
  top: -14px;
}

.current-iteration {
  padding: 12px 12px 0px 12px;
  border-radius: 4px;
  border: 1px solid #54cf94;
}
</style>
