<template>
  <div>
    <div v-if="tasks.length == 0 && setOpen == false" class="empty-roadmap">
      <div>
        <img src="/img/dashboard/empty-sprint.svg" />
      </div>
      <div class="empty-state">There’s nothing here yet.</div>
      <div class="sprint-planning">Sprint Planning</div>
      <div class="sprint-info-list">
        1. Add Tasks to Features/User Stories listed in the Iteration Backlog.
      </div>
      <div class="sprint-info-list">
        2. Drag Tasks from Iteration Backlog and assign them to Sprints.
      </div>
      <div class="sprint-info-list">
        3. Set task current stage ( See Default Workflow ) & assign
        to teammate.
      </div>
      <button
        @click="setOpen = true"
        class="grupa-blue-btn"
        style="margin-top: 48px"
      >
        Get Started
      </button>
    </div>
    <div
      v-if="tasks.length > 0 || setOpen == true"
      class="sprint-container"
      @click="removePrompt"
    >
      <transition name="modal-fade" mode="out-in">
        <div v-if="isIteration" key="1" class="selected-iteration">
          <div class="selected-iteration-name">
            <div style="display: flex">
              <div>
                <img
                  :src="`/img/dashboard/iteration${currentIteration.it_number}.svg`"
                />
              </div>
              <div class="selected-itr-title">Iteration 1 - Must Have’s</div>
            </div>
            <div @click="isIteration = !isIteration" style="cursor: pointer">
              <img :src="`/img/dashboard/minimize-icon.svg`" />
            </div>
          </div>
          <VuePerfectScrollbar class="perfect-scroll-area" :settings="settings">
            <div class="itr-features-div">
              <div class="feature-card-div">
                <v-expansion-panel v-model="currSection">
                  <v-expansion-panel-content
                    v-for="(feature, i) in features"
                    :key="i"
                    v-if="feature.iteration_id == currentIteration.id"
                  >
                    <template v-slot:actions>
                      <img
                        v-if="i == currSection"
                        src="/img/dashboard/chevron-open.svg"
                      />
                      <img v-else src="/img/dashboard/chevron-close.svg" />
                    </template>
                    <template v-slot:header>
                      <div>
                        <div class="tem-feature-header">
                          {{ feature.feature }}
                        </div>
                        <div class="unassigned-task-div">
                          {{ calculateLinkedTask(feature.id) }} tasks assigned
                        </div>
                      </div>
                    </template>
                    <div class="feature-expand-content">
                      <div class="add-new-feature-task">
                        <textarea
                          placeholder="+ Add Task"
                          :style="
                            newTasks[`task${feature.id}`].length < 32
                              ? 'height: 40px;'
                              : ''
                          "
                          v-model="newTasks[`task${feature.id}`]"
                          :id="feature.id"
                          class="text-area-style"
                          @keydown="keydownTask($event)"
                          @blur="createTaskToAFeature($event, feature.id)"
                        >
                        </textarea>
                      </div>
                      <Container
                        group-name="col"
                        drag-class="card-ghost"
                        drop-class="card-ghost-drop"
                      >
                        <Draggable
                          v-for="(task, j) in tasks"
                          :key="j"
                          v-if="
                            task.feature_id == feature.id &&
                            task.sprint_id == null
                          "
                        >
                          <div
                            class="unassigned-task-card"
                            @mousedown="updateMove($event, task)"
                          >
                            {{ task.task }}
                          </div>
                        </Draggable>
                      </Container>
                    </div>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </div>
            </div>
          </VuePerfectScrollbar>
        </div>
        <div v-else key="2" class="iteartion-toggle">
          <img
            @click="isIteration = !isIteration"
            style="cursor: pointer"
            :src="`/img/dashboard/maximize-icon.svg`"
          />
        </div>
      </transition>
      <VuePerfectScrollbar class="perfect-scroll-area" :settings="settings">
        <div class="sprint-wrapper">
          <div
            class="sprint-card-div"
            v-for="(sprint, j) in sprints"
            :key="j"
            v-if="sprint.iteration_id == currentIteration.id"
          >
            <div class="sprint-info">
              <div class="sprint-name">Sprint {{ sprint.sprint }}</div>
              <div style="display: flex">
                <div>
                  <img
                    v-if="sprint.status < 1"
                    :src="imgLink + 'clock-not-started.svg'"
                  />
                  <img
                    v-if="sprint.status == 1"
                    :src="imgLink + 'clock-ongoing.svg'"
                  />
                  <img
                    v-if="sprint.status == 2"
                    :src="imgLink + 'clock-done.svg'"
                  />
                </div>
                <div
                  v-if="sprint.status == 0"
                  @click="openSprintStatus($event, sprint.id)"
                  class="sprint-due-date"
                >
                  Not Started
                </div>
                <div
                  v-if="sprint.status == 2"
                  class="sprint-due-date"
                  style="color: #1b1e22"
                >
                  Completed
                </div>
                <div
                  v-if="sprint.status == 1"
                  @click="openSprintStatus($event, sprint.id)"
                  class="sprint-due-date"
                  :style="sprint.status == 1 ? 'color: #54CF94;' : ''"
                >
                  {{ $moment(sprint.start_date).format("Do MMM") }}-
                  {{ addDaysFromDate(sprint.start_date, 4) }}
                </div>
              </div>
              <SprintStatus
                v-if="currentSprint == sprint.id && isSprintStatus"
                v-bind="{ sprint, stayTop, compareTwoDates }"
              />
            </div>
            <div v-if="sprint.status < 2" class="add-sprint-task">
              <textarea
                v-model="newTask[`task${sprint.id}`]"
                :style="
                  newTask[`task${sprint.id}`].length < 32 ? 'height: 40px;' : ''
                "
                placeholder="+ Add Task or drag from iteration backlog"
                :id="sprint.id"
                @keydown="keydownTask($event)"
                @blur="handleCreateTask($event, sprint.id)"
                class="text-area-style"
              >
              </textarea>
            </div>

            <div
              v-if="sprint.status > 0"
              class="sprint-progress-div"
              :class="{ 'completed-sprint-border': sprint.status == 2 }"
            >
              <div class="flex-progress">
                <div class="sprint-progress-wrap">
                  <div
                    class="sprint-progress-line"
                    :style="
                      sprint.status == 1
                        ? `width:${percentageTasksDone(currentTasks)}%`
                        : ''
                    "
                    :class="{ 'completed-progress-line': sprint.status == 2 }"
                  ></div>
                </div>
                <div class="sprint-percent">
                  {{
                    sprint.status == 1
                      ? parseInt(percentageTasksDone(currentTasks))
                      : "100"
                  }}%
                </div>
              </div>
            </div>
            <div v-else class="sprint-task-divider"></div>
            <!-- <Container>
            <Draggable> -->
            <VuePerfectScrollbar
              class="perfect-scroll-area"
              :settings="settings"
            >
              <div
                class="scroll-sprints-div"
                :class="{
                  'completed-sprint': sprint.status == 2,
                  'ongoing-sprint': sprint.status == 1,
                  'not-started-sprint': sprint.status == 0,
                }"
              >
              <!-- 
                 @drop="(e) => onTaskDrop(sprint.id, e)"
               -->

               <!-- 

                 @drag-start="(e) => dragMan('drag start',e)"
                   @drag-end="(e) => dragMan('drag end', e)"
                   @drop="(e) => dragMan(sprint.id, e)"
                -->
                <Container
                  group-name="col"
                  drag-class="card-ghost"
                  drop-class="card-ghost-drop"
                  :drop-placeholder="dropPlaceholderOptions"
                 
                   @drop="(e) => onTaskDrop(sprint.id, e)"
                  
                  :get-child-payload="getCardPayload(sprint.id)"
                >
                  <Draggable v-for="(task, k) in tasks" :key="k">
                    <div 
                      class="parent-card"
                      v-if="task.sprint_id == sprint.id"
                      @mousedown="movingTask = task.id"
                    >
                      <div
                        class="task-white-card"
                        @click="handleDrawer(task, sprint)"
                      >
                        <div>{{ task.task }}</div>
                      </div>
                      <!-- Card icons div start -->
                      <div class="task-assig-icons">
                        <div style="display: flex">
                          <v-menu
                            class="user-story-menu"
                            bottom
                            origin="center center"
                            transition="scale-transition"
                            offset-y
                            :close-on-content-click="false"
                          >
                            <template slot="activator">
                              <div @click="openLinkTask(task)">
                                <img
                                  v-if="task.feature_id == null"
                                  src="/img/dashboard/task/unlinked-task.svg"
                                />
                                <img
                                  v-if="task.feature_id != null"
                                  src="/img/dashboard/task/link-task.svg"
                                />
                              </div>
                            </template>
                            <LinkTaskToFeature
                              v-bind:linkSpinner="linkSpinner"
                              v-if="isLinkPopup"
                              v-on:handleLinkTask="handleLinkTask"
                            />
                          </v-menu>
                          <!-- For Product Manager -->
                          <v-menu
                            v-if="user.team_role == 'Product Manager'"
                            class="user-story-menu"
                            bottom
                            origin="center center"
                            transition="scale-transition"
                            offset-y
                            :close-on-content-click="false"
                          >
                            <template slot="activator">
                              <div @click="handleAssignTask(task)">
                                <div
                                  class="profile-icon"
                                  v-if="task.teams && task.teams.length > 0"
                                >
                                  <v-avatar
                                    v-for="(member, i) in teamMembers"
                                    :key="i"
                                    v-if="
                                      member.id ==
                                      task.teams[task.teams.length - 1].dev_id
                                    "
                                    :size="32"
                                    color="grey lighten-4"
                                    class="profile_bg"
                                    :style="{
                                      backgroundImage: `url(${
                                        $imageUrl + member.profile_picture ||
                                        test
                                      })`,
                                    }"
                                  ></v-avatar>
                                </div>
                                <img
                                  class="unassign-icon"
                                  v-else
                                  src="/img/dashboard/task/unassign-task.svg"
                                />
                              </div>
                            </template>
                            <AssignTaskPrompt
                              v-if="isAssignPopup"
                              v-on:closePopup="closePopup"
                            />
                          </v-menu>
                          <div v-else>
                            <div
                              class="profile-icon"
                              v-if="task.teams && task.teams.length > 0"
                              @click="handleRejectAction"
                            >
                              <v-avatar
                                v-for="(member, i) in teamMembers"
                                :key="i"
                                v-if="
                                  member.id ==
                                  task.teams[task.teams.length - 1].dev_id
                                "
                                :size="32"
                                color="grey lighten-4"
                                class="profile_bg"
                                :style="{
                                  backgroundImage: `url(${
                                    $imageUrl + member.profile_picture || test
                                  })`,
                                }"
                              ></v-avatar>
                            </div>
                            <img
                              class="unassign-icon"
                              v-else
                              src="/img/dashboard/task/unassign-task.svg"
                            />
                          </div>
                          <!-- For team members -->
                        </div>
                        <div>
                          <img
                            v-if="task.task_status_id == 5"
                            src="/img/sprint/task-completed.svg"
                          />
                          <div v-else>
                            <img
                              class="img-task-category"
                              v-if="task.teams && task.teams.length > 0"
                              :src="
                                imgLink1 +
                                taskStage(
                                  task.teams[task.teams.length - 1].task_type_id
                                ) +
                                '.svg'
                              "
                            />
                            <img
                              v-else
                              src="/img/dashboard/task/task-status.svg"
                            />
                          </div>
                        </div>
                      </div>
                      <!-- Card icons div end -->
                    </div>
                  </Draggable>
                </Container>
              </div>
            </VuePerfectScrollbar>
            <!-- </Draggable>
          </Container> -->
          </div>

          <!--  -->
        </div>
      </VuePerfectScrollbar>
    </div>
    <DialogComponent
      dialogTitle="Development Workflow"
      v-if="openDialog"
      v-bind="{ closeDialog }"
    >
      <div id="main-children">
        <div class="sprint-guide">
          Map out the stages of product development as fits your team and
          teammates’ roles. Click below to edit the stages
        </div>
        <div class="sprint-guide" style="margin-top: 24px">
          The default development workflow (shown below) starts with Product
          Design, then proceeds to the Development/Coding phase and finally QA
          Tests before Deployment.
        </div>
        <div class="workflow-stage">Workflow stages</div>
        <div class="workflow-progress">
          <div style="display: flex" v-for="(stage, i) in Workflow" :key="i">
            <div>
              <img :src="imgLink + stage.img" />
            </div>
            <div class="stage-name">{{ stage.name }}</div>
            <div class="arrow-connect" v-if="i < 3">
              <img :src="imgLink + 'link-arrow.svg'" />
            </div>
          </div>
        </div>
      </div>
      <template v-slot:dialog-footer>
        <button class="grupa-grey-btn" style="margin-right: 24px">
          Edit Workflow
        </button>
        <button
          class="grupa-blue-btn"
          @click="
            setOpen = true;
            openDialog = false;
          "
        >
          Okay, Got it
        </button>
      </template>
    </DialogComponent>
    <ViewSprintDialog
      ref="drawer"
      v-bind="{
        selectedTask,
        selectedSprint,
        handleLinkTask,
        closeTaskOverLay,
      }"
    />
  </div>
</template>

<script>
import { Container, Draggable } from "vue-smooth-dnd";
import { mapActions, mapState } from "vuex";
import LinkTaskToFeature from "./linkTaskFeature";
import AssignTaskPrompt from "@/components/sprintPlanning/assignTaskToTeamMember";
import SprintStatus from "./sprintStatus";
import {
  devCreateTask,
  createTaskToFeature,
  devLinkTask,
  devUpdateTask,
} from "@/api/team";
import DialogComponent from "@/components/Modals/dialogComponent";
import appMixin from "@/mixins/appMixin";
import computeTask from "@/mixins/computeTask";
import ViewSprintDialog from "./viewSprintDialog";
import VuePerfectScrollbar from "vue-perfect-scrollbar";

export default {
  mixins: [appMixin, computeTask],
  props: {},
  components: {
    LinkTaskToFeature,
    AssignTaskPrompt,
    SprintStatus,
    DialogComponent,
    ViewSprintDialog,
    Container,
    Draggable,
    VuePerfectScrollbar,
  },

  data: () => ({
    settings: {
      maxScrollbarLength: 60,
    },
    openDialog: false,
    currSection: null,
    newTasks: {},
    currentTask: {},
    currentSprint: 0,
    isAssignTask: false,
    isLinkTask: false,
    isSprintStatus: false,
    newTask: {},
    Workflow: [
      { name: "Design", img: "workflow1.svg" },
      { name: "Development", img: "workflow2.svg" },
      { name: "QA Tests", img: "workflow3.svg" },
      { name: "Complete", img: "workflow4.svg" },
    ],
    imgLink: "/img/sprint/",
    test: "/img/profile.svg",
    setOpen: false,
    selectedTask: {},
    selectedSprint: {},
    imgLink1: "/img/dashboard/task/",
    dropPlaceholderOptions: {
      className: "drop-preview",
      animationDuration: "150",
      showOnTop: true,
    },
    taskMove: 0,
    isIteration: true,
    isLinkPopup: true,
    isAssignPopup: true,
    linkSpinner: false,
    movingTask: 0,
  }),

  created() {
    this.generateNewTasks();
    this.generateNewTasksFeature();
    this.handleCollapseIteration();
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("devDashboard", ["createProjectTask", "updateProjectTask"]),
    ...mapActions("popupManagement", ["updateCurrentTask"]),

    dragMan(label,e)
    {
      console.log(label+' :: '+JSON.stringify(e))
    },
    scrollHandle(evt) {
      console.log(evt);
    },
    handleCollapseIteration() {
      if (this.project.workspace_progress_level > 7) {
        this.isIteration = false;
      }
    },
    openLinkTask(task) {
      this.isLinkPopup = true;
      this.updateCurrentTask(task);
    },
    handleAssignTask(task) {
      this.isAssignPopup = true;
      this.updateCurrentTask(task);
    },
    openSprintStatus(event, sprintId) {
      event.stopPropagation();
      this.currentSprint = sprintId;
      this.isSprintStatus = true;
      this.isAssignTask = false;
      this.isLinkTask = false;
    },
    generateNewTasks() {
      let obj = {};
      let sprints = this.sprints.filter(
        (sprt) => sprt.iteration_id == this.currentIteration.id
      );
      sprints.map((sprint) => {
        obj[`task${sprint.id}`] = "";
      });
      this.newTask = obj;
    },
    generateNewTasksFeature() {
      let obj = {};
      let features = this.features.filter(
        (feature) => feature.iteration_id == this.currentIteration.id
      );
      features.map((feature) => {
        obj[`task${feature.id}`] = "";
      });
      this.newTasks = obj;
    },
    handleCreateTask(event, sprintId) {
      event.preventDefault();
      let data;
      // Make provision for if adding task to feature
      let task = this.newTask[`task${sprintId}`];
      let el = event.target;
      if (task !== "") {
        el.classList.add("textShine");
        data = {
          sprint_id: sprintId,
          task,
          date_timezone: this.currentDate(),
        };
        devCreateTask(data)
          .then((res) => {
            if (res.data.status == 1) {
              this.createProjectTask(res.data.task);
              this.newTask[`task${sprintId}`] = "";
              el.classList.remove("textShine");
            }
          })
          .catch((err) => {
            this.$developConsole(err.response, "500");
            if (err.response && err.response.data.status_code == 500) {
              this.setErrorMsg(err.response.data.message);
            }
            el.classList.remove("textShine");
          });
      }
    },
    createTaskToAFeature(event, featureId) {
      event.preventDefault();
      let data;
      // Make provision for if adding task to feature
      let task = this.newTasks[`task${featureId}`];
      let el = event.target;
      if (task !== "") {
        el.classList.add("textShine");
        data = {
          feature_id: featureId,
          task,
          date_timezone: this.currentDate(),
        };
        createTaskToFeature(data)
          .then((res) => {
            if (res.data.status == 1) {
              this.createProjectTask(res.data.task);
              this.newTasks[`task${featureId}`] = "";
              el.classList.remove("textShine");
            }
          })
          .catch((err) => {
            this.$developConsole(err.response.data.status_code, "500");
            if (err.response && err.response.data.status_code == 500) {
              this.setErrorMsg(err.response.data.message);
            }
            el.classList.remove("textShine");
          });
      }
    },
    keydownTask(event) {
      if (event.key != "Enter") {
        return;
      }
      event.target.blur();
    },
    closeDialog() {
      this.openDialog = false;
    },
    removePrompt() {
      this.isAssignTask = false;
      this.isLinkTask = false;
      this.isSprintStatus = false;
    },
    stayTop(event) {
      event.stopPropagation();
    },
    handleDrawer(task, sprint) {
      this.selectedTask = JSON.parse(JSON.stringify(task));
      this.selectedSprint = sprint;
      this.$refs.drawer.toggleDrawer();
    },
    closeTaskOverLay() {
      this.$refs.drawer.toggleDrawer();
    },
    calculateLinkedTask(featureId) {
      let totalTasks = this.tasks.filter(
        (task) => task.feature_id == featureId
      );
      let linkedTasks = totalTasks.filter((task) => task.sprint_id !== null);
      return `${linkedTasks.length}/${totalTasks.length}`;
    },
    onCardDrop(columnId, dropResult) {
      let taskList = JSON.parse(JSON.stringify(this.tasks));
      if (dropResult.addedIndex) {
        let newTask = this.taskMove;
        newTask.sprint_id = columnId;
        let taskIndex = taskList.findIndex((task) => task.id == newTask.id);
        taskList[taskIndex] = newTask;
        this.updateProjectTask(taskList);
        let payload = { sprint_id: columnId, task_id: newTask.id };
        this.linkTaskToSprint(payload);
      }
    },
    updateMove(event, task) {
      this.taskMove = JSON.parse(JSON.stringify(task));
    },
    async handleLinkTask(data) {
      this.linkSpinner = true;
      let { task_id } = data;
      let taskList = JSON.parse(JSON.stringify(this.tasks));
      await devLinkTask(task_id, data)
        .then((res) => {
          if (res.data) {
            let taskIndex = taskList.findIndex((task) => task.id == task_id);
            this.setSuccessMsg("Task linked to feature successfully");
            taskList[taskIndex] = res.data.task;
            this.updateProjectTask(taskList);
            this.linkSpinner = false;
            this.isLinkPopup = false;
          }
        })
        .catch((err) => {
          this.$developConsole(err);
        });
    },
    async linkTaskToSprint(payload) {
      let { task_id } = payload;
      await devLinkTask(task_id, payload)
        .then((res) => {})
        .catch((err) => {
          this.$developConsole(err);
        });
    },
    closePopup() {
      this.isAssignPopup = false;
    },
    onTaskDrop(columnId, dropResult) {
    //  console.log(dropResult)
     // console.log(columnId)
      if (dropResult.removedIndex !== null || dropResult.addedIndex !== null) {
        if (dropResult.addedIndex !== null) {
          let payload = {
            sprintId: columnId,
            position: dropResult.addedIndex,
            taskId: dropResult.payload.id,
            movedTask: dropResult.payload,
          };
          let newTask = {
            ...payload.movedTask,
            sprint_id: payload.sprintId,
          };
          let taskList = JSON.parse(JSON.stringify(this.tasks));
          let taskIndex = taskList.findIndex(
            (task) => task.id == payload.taskId
          );
          taskList[taskIndex] = newTask;
          this.updateProjectTask(taskList);
          this.handleMoveTaskToNewSprint(payload.taskId, payload.sprintId);
        }
      }
    },
    getCardPayload(columnId) {
      return (index) => {
        return this.tasks.filter((p) => p.id === this.movingTask)[0];
      };
    },
    handleRejectAction() {
      this.setErrorMsg(
        "Reach out to the Product Manager to perform this action"
      );
    },
    async handleMoveTaskToNewSprint(task_id, sprint_id) {
      let payload = {
        task_id,
        sprint_id,
        sprint_destination_id: sprint_id,
        action_performed_id: 1,
        date_timezone: this.currentDate(),
      };
      await devLinkTask(task_id, payload)
        .then((res) => {
          if (res.data) {
            // console.log("move", res.data);
          }
        })
        .catch((err) => {
          this.$developConsole(err);
        });
    },
  },
  computed: {
    ...mapState("devDashboard", [
      "selectedProjectDoc",
      "selectedProject",
      "teamInfo",
    ]),
    user() {
      return this.teamInfo.user;
    },
    project() {
      return this.selectedProject;
    },
    currentIteration() {
      return this.selectedProjectDoc.iterations.find(
        (itr) => itr.id == this.selectedProject.current_iteration
      );
    },
    features() {
      return this.selectedProjectDoc.features;
    },
    sprints() {
      return this.selectedProjectDoc.sprints;
    },
    ongoingSprint() {
      return this.selectedProjectDoc.sprints.find(
        (sprint) => sprint.status == 1
      );
    },
    tasks() {
      return this.selectedProjectDoc.tasks;
      // return this.selectedProjectDoc.tasks.filter(
      //   (task) => task.iteration_id == this.currentIteration.id
      // );
    },
    currentTasks() {
      if (this.ongoingSprint != undefined) {
        return this.selectedProjectDoc.tasks.filter(
          (task) => task.sprint_id == this.ongoingSprint.id
        );
      }
    },

    teamMembers() {
      return this.teamInfo.team.members;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.sprint-planning {
  font-weight: bold;
  font-size: 16px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 48px;
}
.sprint-info-list {
  font-size: 18px;
  line-height: 130%;
  color: #979da5;
  margin-top: 16px;
  text-align: center;
  font-weight: bold;
}
.sprint-info-list span {
  color: #0781f2;
}
.sprint-container {
  display: flex;
}
.iteartion-toggle {
  background: #ffffff;
  border-top: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7;
  padding: 24px 24px 24px 12px;
  height: 72px;
}
.selected-iteration {
  background: #ffffff;
  /* width: 396px; */
}
.selected-iteration-name {
  padding: 24px 36px 24px 32px;
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7;
}
.selected-itr-title {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 4px;
}
.minimize-icon {
  margin-left: 128px;
}
.itr-features-div {
  padding: 0px 32px 8px 32px;
  max-height: 540px;
  /* overflow: hidden;
  overflow-y: scroll; */
}
.tem-feature-header {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 252px;
}
.feature-card-div {
  width: 332px;
}
.unassigned-task-div {
  margin-top: 8px;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.feature-expand-content {
  background: #f7f7f8;
  padding: 16px 16px 24px;
}
.unassigned-task-card {
  padding: 12px;
  background: #ffffff;
  border-radius: 4px;
  margin-top: 8px;
  margin-bottom: 8px;
  cursor: move;
}
.add-new-feature-task textarea {
  background: #ffffff;
  border-radius: 4px;
  padding: 12px;
}
.sprint-wrapper {
  padding: 32px;
  display: flex;
  padding-right: 48px;
  /* min-height: 619px; */
  /* Remove later */
  /* overflow-x: scroll; */
  height: calc(100vh - 130px);
}
.sprint-card-div {
  margin-right: 32px;
}
.sprint-info {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.sprint-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.sprint-due-date {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 4px;
  cursor: pointer;
}
.add-sprint-task {
  margin-top: 16px;
  background: #ffffff;
  border-radius: 4px;
  width: 310px;
}
.add-sprint-task textarea {
  padding: 16px;
}
.sprint-task-divider {
  margin-top: 16px;
  margin-bottom: 16px;
  border-top: 1px solid #e4e5e7;
}
.scroll-sprints-div {
  width: 310px;
}
.not-started-sprint {
  height: calc(100vh - 280px);
}
.ongoing-sprint {
  height: calc(100vh - 340px);
}
.completed-sprint {
  height: calc(100vh - 280px);
}
.sprint-hori-bar:hover {
  overflow-y: scroll;
}
.parent-card {
  position: relative;
}
.task-white-card {
  background: #ffffff;
  border-radius: 4px;
  padding: 16px;
  padding-bottom: 64px;
  text-align: left;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  /* margin-top: 8px; */
  margin-bottom: 8px;
  cursor: pointer;
}
.task-assig-icons {
  display: flex;
  justify-content: space-between;
  position: absolute;
  width: 283px;
  /* z-index: 100; */
  bottom: 11px;
  left: 16px;
}
.unassign-icon {
  margin-left: 16px;
}
.task-assig-icons img {
  cursor: pointer;
}
.task-assig-icons .profile-icon {
  margin-left: 16px;
}
/* Dialog CSS Started*/
#main-children {
  width: 447px;
  text-align: left;
}
.sprint-guide {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.workflow-stage {
  font-size: 12px;
  line-height: 140%;
  text-transform: uppercase;
  color: #979da5;
  margin-top: 24px;
}
.workflow-progress {
  margin-top: 16px;
  display: flex;
}
.stage-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-left: 4px;
  margin-top: 2px;
}
.arrow-connect {
  margin: 0px 11px;
}
/* Dialog CSS Ends*/

/* Sprint Progress Line */
.sprint-progress-div {
  margin: 16px 0px;
  padding: 22px 0px;
  border-top: 1px solid #54cf94;
  border-bottom: 1px solid #54cf94;
}
.completed-sprint-border {
  border-top: 1px solid #e4e5e7;
  border-bottom: 1px solid #e4e5e7;
}
.sprint-progress-wrap {
  background: #e4e5e7;
  border-radius: 4px;
  width: 279px;
  height: 4px;
  margin-top: 5px;
}
.flex-progress {
  display: flex;
  justify-content: space-between;
}
.sprint-progress-line {
  height: 100%;
  width: 20%;
  background: #0781f2;
  border-radius: 4px;
}
.completed-progress-line {
  width: 100%;
  background: #1b1e22;
}
.sprint-percent {
  font-size: 12px;
  line-height: 130%;
  color: #979da5;
}
.img-task-category {
  width: 32px;
  height: 32px;
}
.text-area-style {
  font-size: 14px;
}
</style>
<style>
.feature-card-div .v-expansion-panel__container {
  margin-top: 16px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  width: 332px;
}
.feature-card-div .v-expansion-panel {
  -webkit-box-shadow: none;
}
.feature-card-div .v-expansion-panel__container:first-child {
  border-top: 1px solid #e4e5e7 !important;
}
.feature-card-div .v-expansion-panel__header {
  padding: 16px;
}
.feature-card-div
  .v-expansion-panel__container--active
  .v-expansion-panel__header {
  background: #f7f7f8;
}

/* Drag and drop Css */
.column-drag-handle {
  cursor: move;
  padding: 5px;
}

.card-ghost {
  transition: transform 0.18s ease;
  transform: rotateZ(5deg);
}

.card-ghost-drop {
  transition: transform 0.18s ease-in-out;
  transform: rotateZ(0deg);
}
/* Drag and CSS ends */

/* Popup */
.assign-team-task {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 2px 5px 15px rgba(27, 30, 34, 0.07);
  border-radius: 4px;
  width: 317px;
}

.prompt-header {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  padding: 24px 24px 12px;
}
.members-list-task {
  padding: 9px 24px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.members-list-task:hover {
  background: #f7f7f8;
}
.member-task {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin: 4px 16px 0px 8px;
}
.members-list-task .profile-icon {
  margin-left: 16px;
}
</style>

<style>
.smooth-dnd-container.vertical > .smooth-dnd-draggable-wrapper {
  overflow: unset;
}
</style>