var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.features.length == 0 && _vm.startRoad == false
        ? _c("div", { staticClass: "empty-roadmap" }, [
            _vm._m(0),
            _c("div", { staticClass: "empty-state" }, [
              _vm._v("There’s nothing here yet."),
            ]),
            _c("div", { staticClass: "roadmap-bold" }, [
              _vm._v("Product Roadmapping"),
            ]),
            _vm._m(1),
            _vm._m(2),
            _vm._m(3),
            _vm._m(4),
            _c(
              "button",
              {
                staticClass: "grupa-blue-btn",
                staticStyle: { "margin-top": "48px" },
                on: {
                  click: function ($event) {
                    _vm.startRoad = true
                  },
                },
              },
              [_vm._v("\n      Get Started\n    ")]
            ),
          ])
        : _c(
            "div",
            [
              _c(
                "VuePerfectScrollbar",
                {
                  staticClass: "perfect-scroll-area",
                  attrs: { settings: _vm.settings },
                },
                [
                  _c(
                    "div",
                    { staticClass: "space-wrapper", attrs: { id: "it-cont" } },
                    [
                      _vm._l(_vm.iterations, function (iteration, index) {
                        return _c(
                          "div",
                          {
                            key: index,
                            staticClass: "iteration-card",
                            class: {
                              "current-iteration":
                                iteration.id == _vm.project.current_iteration &&
                                _vm.project.workspace_progress_level >= 7,
                            },
                          },
                          [
                            _vm.project.workspace_progress_level >= 7 &&
                            iteration.id == _vm.project.current_iteration
                              ? _c("div", { staticClass: "lock-iteration" }, [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/dashboard/green-lock.svg",
                                      alt: "",
                                    },
                                  }),
                                ])
                              : _vm._e(),
                            _c(
                              "div",
                              {
                                staticClass: "iteration-info",
                                on: {
                                  mouseover: function ($event) {
                                    return _vm.handleItrOver(iteration.id)
                                  },
                                  mouseleave: function ($event) {
                                    _vm.itrOver = false
                                    _vm.iterationHoverId = ""
                                  },
                                },
                              },
                              [
                                _c("div", { staticClass: "iteration-name" }, [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/img/dashboard/iteration" +
                                        (index + 1) +
                                        ".svg",
                                    },
                                  }),
                                  _vm._v(
                                    "\n              Iteration " +
                                      _vm._s(index + 1) +
                                      " -\n              "
                                  ),
                                  _c("span", [
                                    _vm._v(_vm._s(iteration.name + "’s")),
                                  ]),
                                ]),
                                _vm.iterationHoverId == iteration.id &&
                                _vm.itrOver
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "delete-itr-div",
                                        on: {
                                          click: function ($event) {
                                            return _vm.handleDeletePrompt(
                                              iteration
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              Delete\n            "
                                        ),
                                      ]
                                    )
                                  : _c(
                                      "div",
                                      { staticClass: "iteration-duration" },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(
                                              _vm.project.iteration_cycle
                                            ) +
                                            "wks\n            "
                                        ),
                                      ]
                                    ),
                              ]
                            ),
                            _c("div", { staticClass: "add-new-feature" }, [
                              _c("textarea", {
                                directives: [
                                  {
                                    name: "model",
                                    rawName: "v-model",
                                    value:
                                      _vm.featuresObj[
                                        "newFeature" + iteration.id
                                      ],
                                    expression:
                                      "featuresObj[`newFeature${iteration.id}`]",
                                  },
                                ],
                                staticClass: "text-area-style",
                                staticStyle: { "font-size": "14px" },
                                attrs: {
                                  placeholder:
                                    "+ Add Product Feature or User story",
                                  id: iteration.id,
                                },
                                domProps: {
                                  value:
                                    _vm.featuresObj[
                                      "newFeature" + iteration.id
                                    ],
                                },
                                on: {
                                  keydown: function ($event) {
                                    return _vm.keydownFeature($event)
                                  },
                                  blur: function ($event) {
                                    return _vm.handleAddFeature(
                                      $event,
                                      iteration.id
                                    )
                                  },
                                  input: function ($event) {
                                    if ($event.target.composing) {
                                      return
                                    }
                                    _vm.$set(
                                      _vm.featuresObj,
                                      "newFeature" + iteration.id,
                                      $event.target.value
                                    )
                                  },
                                },
                              }),
                            ]),
                            _c("div", {
                              staticClass: "divider-line",
                              style:
                                _vm.isAddFeature(
                                  _vm.project.workspace_progress_level,
                                  _vm.project.current_iteration,
                                  iteration.id
                                ) == false
                                  ? "margin-top: 16px"
                                  : "",
                            }),
                            _c(
                              "VuePerfectScrollbar",
                              {
                                staticClass: "perfect-scroll-area",
                                attrs: { settings: _vm.settings },
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass: "features-wrapper",
                                    class: {
                                      "locked-itearation": iteration.status > 0,
                                      "unlocked-iteration":
                                        iteration.status == 0,
                                    },
                                  },
                                  [
                                    _c(
                                      "Container",
                                      {
                                        attrs: {
                                          "group-name": "col",
                                          "drag-class": "card-ghost",
                                          "drop-class": "card-ghost-drop",
                                          "drop-placeholder":
                                            _vm.dropPlaceholderOptions,
                                          "get-child-payload":
                                            _vm.getCardPayload(iteration.id),
                                        },
                                        on: {
                                          drop: function (e) {
                                            return _vm.onCardDrop(
                                              iteration.id,
                                              e
                                            )
                                          },
                                        },
                                      },
                                      _vm._l(
                                        _vm.features,
                                        function (feature, k) {
                                          return _c("Draggable", { key: k }, [
                                            feature.iteration_id == iteration.id
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "feature-card",
                                                    on: {
                                                      mousedown: function (
                                                        $event
                                                      ) {
                                                        _vm.currentFeature =
                                                          feature.id
                                                      },
                                                      click: function ($event) {
                                                        return _vm.handleDrawer(
                                                          feature,
                                                          index
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c("div", [
                                                      _vm._v(
                                                        _vm._s(feature.feature)
                                                      ),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "feature-icons",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "feature-chat",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: "/img/dashboard/message-icon.svg",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " 0\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "number-of-sprint",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: "/img/dashboard/sprint-icon.svg",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  feature.tasks
                                                                    .length
                                                                ) +
                                                                "\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "feature-upload",
                                                          },
                                                          [
                                                            _c("img", {
                                                              attrs: {
                                                                src: "/img/dashboard/attach.svg",
                                                              },
                                                            }),
                                                            _vm._v(
                                                              " 0\n                      "
                                                            ),
                                                          ]
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      _c("div", { staticClass: "add-iteration" }, [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: !_vm.iterationSpinner,
                                expression: "!iterationSpinner",
                              },
                            ],
                            on: { click: _vm.addNewIteration },
                          },
                          [_vm._v("\n            + Add Iteration\n          ")]
                        ),
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.iterationSpinner,
                                expression: "iterationSpinner",
                              },
                            ],
                          },
                          [
                            _c("img", {
                              staticClass: "button-spinner",
                              staticStyle: { "margin-left": "20px" },
                              attrs: { src: "/img/lightbox/preloader.gif" },
                            }),
                          ]
                        ),
                      ]),
                    ],
                    2
                  ),
                ]
              ),
            ],
            1
          ),
      _c(
        "FeatureDrawer",
        _vm._b(
          { ref: "drawer" },
          "FeatureDrawer",
          {
            selectedFeature: _vm.selectedFeature,
            updateFeature: _vm.updateFeature,
            iterationNum: _vm.iterationNum,
            closeFeatureOverLay: _vm.closeFeatureOverLay,
          },
          false
        )
      ),
      _vm.isDeleteItr
        ? _c(
            "DeleteItrModal",
            _vm._b(
              {},
              "DeleteItrModal",
              {
                closeDeleteItr: _vm.closeDeleteItr,
                deleteIteration: _vm.deleteIteration,
                delSpinner: _vm.delSpinner,
                handleDeleteIteration: _vm.handleDeleteIteration,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/img/dashboard/empty-roadmap.svg" } }),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "roadmapping-info" }, [
      _vm._v("\n      1.\n      "),
      _c("span", [
        _vm._v(
          "\n        Start by putting the most important Product Features or User Stories\n        in Iteration 1"
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "roadmapping-info" }, [
      _vm._v("2. "),
      _c("span", [_vm._v("Add other iterations")]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "roadmapping-info" }, [
      _vm._v("\n      3.\n      "),
      _c("span", [
        _vm._v("Plan the Sprints for\n        "),
        _c("span", { staticStyle: { "font-weight": "bold" } }, [
          _vm._v("Iteration 1"),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "roadmapping-info" }, [
      _vm._v("\n      4. "),
      _c("span", [_vm._v("Share Roadmap with Product Owner")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }