<template>
  <div class="link-task-wrap">
    <div class="search-title">
      <div>
        Link Task to Feature or User Story
        <img
          src="/img/lightbox/preloader.gif"
          style="float: right"
          class="button-spinner"
          v-show="linkSpinner"
        />
      </div>
      <input type="text" v-model="searchBox" class="feature-search-box" />
    </div>
    <div
      class="user-story-list"
      v-for="(feature, i) in filterFeature"
      :key="i"
      v-if="feature.feature !== ' '"
      @click="handleLinkTaskToFeature(feature.id)"
    >
      {{ feature.feature }}
      <img
        v-if="currentTask.feature_id == feature.id"
        style="float: right"
        src="/img/dashboard/task/checkmark.svg"
      />
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import appMixin from "@/mixins/appMixin";

export default {
  mixins: [appMixin],
  props: {
    linkSpinner: {
      type: Boolean,
      required: true,
    },
  },
  components: {},

  data: () => ({
    currentHover: "",
    selected: false,
    searchBox: "",
  }),
  created() {},
  methods: {
    ...mapActions("devDashboard", ["updateProjectTask"]),
    async handleLinkTaskToFeature(featureId) {
      let task_id = this.currentTask.id;
      let data = {
        feature_id: featureId,
        task_id,
        date_timezone: this.currentDate(),
      };
      this.$emit("handleLinkTask", data);
    },
  },
  computed: {
    ...mapState("devDashboard", ["selectedProjectDoc", "selectedProject"]),
    ...mapState("popupManagement", ["currentTask"]),
    currentIteration() {
      return this.selectedProjectDoc.iterations.find(
        (itr) => itr.id == this.selectedProject.current_iteration
      );
    },
    features() {
      return this.selectedProjectDoc.features.filter(
        (ftr) => ftr.iteration_id == this.currentIteration.id
      );
    },
    filterFeature() {
      if (this.features.length > 0) {
        let features = JSON.parse(JSON.stringify(this.features));
        return features.filter((feature) =>
          feature.feature.toUpperCase().match(this.searchBox.toUpperCase())
        );
      }
    },
    tasks() {
      return this.selectedProjectDoc.tasks;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.link-task-wrap {
  /* position: absolute; */
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 2px 5px 15px rgba(27, 30, 34, 0.07);
  border-radius: 4px;
  width: 410px;
  /* top: 37px; */
  /* z-index: 60; */
  text-align: left;
}
.search-title {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  padding: 24px 24px 16px;
}
.feature-search-box {
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  /* width: 362px; */
  height: 35.11px;
  margin-top: 16px;
  width: 100%;
}
.user-story-list {
  padding: 12px 24px;
  cursor: pointer;
}
.user-story-list:hover {
  background: #f7f7f8;
}
.feature-search-box:hover {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
}
.feature-search-box:focus {
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.2);
  border: 1px solid #e4e5e7;
  outline: 0;
}
</style>
