var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "Sprint-status-div",
      on: {
        click: function ($event) {
          return _vm.stayTop($event)
        },
      },
    },
    [
      _c("div", { staticClass: "div-sprint-info" }, [
        _c("div", { staticClass: "sprint-name" }, [
          _vm._v("Sprint " + _vm._s(_vm.sprint.sprint)),
        ]),
        _vm.sprint.status == 0
          ? _c("div", { staticClass: "grey-sprint-text" }, [
              _vm._v("Not Started"),
            ])
          : _vm._e(),
        _vm.sprint.status == 2
          ? _c(
              "div",
              {
                staticClass: "grey-sprint-text",
                style:
                  _vm.compareTwoDates(_vm.sprint.due_date) < 0
                    ? "color: #DE9221;"
                    : "color: #54CF94;",
              },
              [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.compareTwoDates(_vm.sprint.due_date) < 0
                        ? Math.abs(_vm.compareTwoDates(_vm.sprint.due_date)) +
                            " days overdue"
                        : "+ " +
                            _vm.compareTwoDates(_vm.sprint.due_date) +
                            " days left"
                    ) +
                    "\n    "
                ),
              ]
            )
          : _vm._e(),
      ]),
      _vm._m(0),
      _c("div", { staticClass: "sprint-overdue-date" }, [
        _vm._m(1),
        _vm.sprint.start_date == null
          ? _c("div", [_vm._v("Timeline not set")])
          : _vm._e(),
        _vm.sprint.start_date !== null
          ? _c("div", [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$moment(_vm.sprint.start_date).format("Do MMM")) +
                  "-\n      " +
                  _vm._s(_vm.addDaysFromDate(_vm.sprint.start_date, 4)) +
                  "\n    "
              ),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "sprint-goal" }, [
      _c("div", { staticClass: "grey-sprint-text" }, [_vm._v("SPRINT GOAL")]),
      _c("div", { staticClass: "sprint-goal-text" }, [
        _vm._v(
          "\n      Design Sprint 2 Tasks and build back-end architecture.\n    "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/img/sprint/clock-not-started.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }