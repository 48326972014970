<template>
  <DialogComponent
    dialogTitle="Delete Iteration"
    v-bind:closeDialog="closeDeleteItr"
  >
    <div id="main-children">
      <div class="delete-itr-msg">
        You are about to delete
        <span style="font-weight: bold">
          Iteration {{ deleteIteration.it_number }}</span
        >
        and all features, tasks and comments associated with Iteraion
        {{ deleteIteration.it_number }}
      </div>
      <div class="delete-warning-msg">
        This action is irreversible, tick the boxes below to proceed
      </div>
      <div class="delete-child-list">
        <div @click="isCheckFeatures = !isCheckFeatures">
          <img
            :src="`/img/onboarding/client/${
              isCheckFeatures ? 'checked' : 'unchecked'
            }.svg`"
            alt=""
          />
        </div>
        <div>Delete Iteration {{ deleteIteration.it_number }}</div>
      </div>
      <div class="delete-child-list">
        <div @click="isCheckTasks = !isCheckTasks">
          <img
            :src="`/img/onboarding/client/${
              isCheckTasks ? 'checked' : 'unchecked'
            }.svg`"
            alt=""
          />
        </div>
        <div>Delete all its features, tasks and comments</div>
      </div>
    </div>
    <template v-slot:dialog-footer>
      <button
        @click="closeDeleteItr"
        class="grupa-white-btn"
        style="margin-right: 24px"
      >
        Cancel
      </button>
      <button
        :class="enableBtn ? 'grupa-red-btn' : null"
        class="grupa-disabled-red-btn"
        :disabled="!enableBtn"
        @click="handleDeleteIteration(deleteIteration.id)"
      >
        Delete
        <img
          src="/img/lightbox/preloader.gif"
          style="margin-left: 20px"
          class="button-spinner"
          v-show="delSpinner"
        />
      </button>
    </template>
  </DialogComponent>
</template>

<script>
import DialogComponent from "@/components/Modals/dialogComponent";

export default {
  props: {},
  components: {
    DialogComponent,
  },
  props: {
    closeDeleteItr: {
      type: Function,
    },
    deleteIteration: {
      type: Object,
    },
    delSpinner: {
      type: Boolean,
    },
    handleDeleteIteration: {
      type: Function,
    },
  },

  data: () => ({
    isCheckFeatures: false,
    isCheckTasks: false,
  }),

  created() {},
  methods: {},
  computed: {
    enableBtn() {
      if (this.isCheckFeatures && this.isCheckTasks) {
        return true;
      } else {
        return false;
      }
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.delete-warning-msg,
.delete-itr-msg {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 356px;
  text-align: left;
}
.delete-warning-msg {
  color: #6f7680;
  margin-top: 24px;
}
.delete-child-list {
  font-size: 14px;
  line-height: 130%;
  display: flex;
  color: #53585f;
  margin-top: 24px;
}
.delete-child-list div:last-child {
  margin-left: 8px;
}
</style>
