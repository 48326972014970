<template>
  <div class="feature-drawer">
    <v-navigation-drawer v-model="openDrawer" right absolute temporary>
      <div class="drawer-wrapper">
        <div class="toolbar">
          <div class="trash-task" style="padding-left:20px;padding-right:20px" @click="toggleDrawer">
              <img src="/img/dashboard/close-image.svg" style="width:25px" alt="" />
            </div>
          <div class="drawer-title">Product Feature</div>

          
            
          <div class="toolbar-action">



            <!-- @close="drawerIsOpen = false" -->
            <div class="trash-task" @click="handleDeleteFeature">
              <img src="/img/dashboard/trash-icon.svg" alt="" />
            </div>


             
          </div>
        </div>
        <div class="drawer-content">
          <div class="drawer-left-box">
            <div class="drawer-top-content">
              <div class="drawer-feature-content">
                <textarea
                  placeholder="Add Feature"
                  v-model="selectedFeature.feature"
                  class="text-area-style feature-title"
                  @blur="updateFeature($event, (type = 'feature'))"
                  @keydown="keydownFeature($event)"
                >
                </textarea>
              </div>
              <div class="drawer-add-desc">
                <textarea
                  placeholder="Add description"
                  v-model="selectedFeature.description"
                  class="text-area-style feature-description"
                  @blur="updateFeature($event, (type = 'description'))"
                  @keydown="keydownFeature($event)"
                >
                </textarea>
              </div>
            </div>
            <div class="iteration-progress">
              <div class="drawer-itr-name">
                <span>Iteration:</span>
                <img :src="`/img/dashboard/iteration1.svg`" />
                <span>Iteration {{ iterationNum + 1 }}</span>
              </div>
              <div class="progress-bar-div">
                <div class="progress-title">Progress:</div>
                <div class="progress-bar"></div>
                <div class="progress-percentage">0%</div>
              </div>
            </div>
           
            <div class="div-drawer-tasks">
              <div class="drawer-task-count">
                <img src="/img/dashboard/sprint-icon.svg" />
                <span>Tasks ({{ tasks.length }})</span>
              </div>
              <textarea
                placeholder="+ Add Task"
                class="text-area-style dark-textarea"
                v-model="newTask"
                @blur="handleAddTask($event)"
                @keydown="keydownFeature($event)"
              ></textarea>
              <div class="feature-tasks-list">
                <div
                  class="feature-task-div"
                  v-for="(task, k) in tasks"
                  :key="k"
                >
                  <div class="feature-task-content">
                    {{ task.task }}
                  </div>
                  <div class="trash-icon">
                    <img
                      @click="openDeleteModal(task.id)"
                      src="/img/dashboard/delete-icon.svg"
                    />
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="drawer-bottom-content">
              <div class="attachment-title">
                <div class="attach-file-count">
                  <img
                    src="/img/dashboard/attach.svg"
                    style="margin-right: 4px"
                  />
                  ATTACHMENTS (3)
                </div>
                <div @click="openFile()" class="attach-file">
                  + Attach File
                  <input
                    type="file"
                    accept="*"
                    name="somename"
                    size="chars"
                    class="input_file"
                    @blur="handleFileUpload"
                    @change="onFileChange"
                  />
                </div>
              </div>
              <div
                v-if="otherDoc.length > 0"
                v-for="(file, j) in otherDoc"
                :key="j"
                class="feature-uploaded-files"
              >
                <div style="display: flex">
                  <div class="file-icon">
                    <img src="/img/dashboard/file-icon.svg" />
                  </div>
                  <div class="file-long-name">
                    <div>
                      {{ file.name }}
                    </div>
                    <div>
                      <span>{{ file.size / 100 }}kb</span>
                    </div>
                  </div>
                </div>
                <div class="file-view-icon">
                  <img src="/img/dashboard/expand-icon.svg" />
                </div>
                <div class="pdf-uplaoding">
                  <img src="/img/dashboard/close-image.svg" alt="" />
                </div>
              </div>
              <div class="drawer-uploaded-doc">
                <div
                  v-for="(file, k) in uploadedFiles"
                  :key="k"
                  v-if="file.type.split('/')[0] == 'image'"
                  class="uploaded-image-cover"
                >
                  <div
                    class="banner-position"
                    :style="{
                      backgroundImage: `url(${file.url})`,
                    }"
                  >
                    <div class="uploading-wrapper" v-if="file.loading">
                      <div class="div-uploading">
                        <img src="/img/dashboard/close-image.svg" alt="" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="feature-uploaded-files">
                <div style="display: flex">
                  <div class="file-icon">
                    <img src="/img/dashboard/file-icon.svg" />
                  </div>
                  <div class="file-long-name">
                    <div>
                      Title of the uploaded document that will be on one
                      line....pdf
                    </div>
                    <div><span>345.6kb</span></div>
                  </div>
                </div>
                <div class="file-view-icon">
                  <img src="/img/dashboard/expand-icon.svg" />
                </div>
                <div class="pdf-uplaoding">
                  <img src="/img/dashboard/close-image.svg" alt="" />
                </div>
              </div>
            </div> -->
          </div>
          <div class="drawer-right-box">
            <!-- <FeatureActivity v-bind:featureId="selectedFeature.id" /> -->
          </div>
        </div>
        <DeleteModal
          v-if="openDialog"
          v-bind="{ closeDialog, deleteItem, spinner, deleteFeature }"
          v-bind:handleDelete="handleDeleteTask"
        />
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
import {
  createTaskToFeature,
  devDeleteTask,
  devUploadDocument,
  devDeleteFeature,
} from "@/api/team";
// import FeatureActivity from "@/components/feature/featureActivity";
import DeleteModal from "@/components/Modals/deleteModal";
export default {
  props: {
    selectedFeature: Object,
    iterationNum: Number,
    updateFeature: Function,
    closeFeatureOverLay: {
      type: Function,
    },
  },
  components: {
    // FeatureActivity,
    DeleteModal,
  },

  data: () => ({
    openDrawer: false,
    featureContent: `While user stories or product features can be in multiple but not more than 2 lines on the main page, everything shows here`,
    image1: "",
    image2: "/img/dashboard/dummy/image2.png",
    newTask: "",
    openDialog: false,
    delTaskId: 0,
    deleteItem: "Task",
    spinner: false,
    fileUploaded: null,
    fileUrl: "",
    uploadedFiles: [
      {
        name: "image1.svg",
        type: "image/jpeg",
        url: "/img/dashboard/dummy/image1.svg",
      },
      {
        name: "image2.svg",
        type: "image/jpeg",
        url: "/img/dashboard/dummy/image1.svg",
      },
    ],
    otherDoc: [],
  }),

  created() {},
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("devDashboard", [
      "createProjectTask",
      "updateProjectFeatures",
      "updateSelectedProjectDoc",
    ]),
    toggleDrawer() {
      this.openDrawer = !this.openDrawer;
    },
    keydownFeature(event) {
      if (event.key != "Enter") {
        return;
      }
      event.target.blur();
    },
    handleAddTask(event) {
      event.preventDefault();
      let currentDate = new Date().toJSON().slice(0, 10);
      let data;
      let task = this.newTask;
      let el = event.target;
      let { id } = this.selectedFeature;
      if (task !== "") {
        el.classList.add("textShine");
        data = {
          feature_id: id,
          task,
          date_timezone: currentDate,
        };
        createTaskToFeature(data)
          .then((res) => {
            if (res.data.status == 1) {
              this.createProjectTask(res.data.task);
              this.newTask = "";
              el.classList.remove("textShine");
              this.setSuccessMsg("Task Added!!");
            }
          })
          .catch((err) => {
            el.classList.remove("textShine");
            this.setErrorMsg("Please try again!!");
          });
      }
    },
    closeDialog() {
      this.openDialog = false;
    },
    openDeleteModal(id) {
      this.deleteItem = "Task";
      this.delTaskId = id;
      this.openDialog = true;
    },
    handleDeleteFeature() {
      this.deleteItem = "Feature";
      this.openDialog = true;
    },
    handleDeleteTask() {
      let taskId = this.delTaskId;
      this.spinner = true;
      devDeleteTask(taskId)
        .then((res) => {
          if (res.data.status == 1) {
            this.spinner = false;
            this.updateSelectedProjectDoc(res.data.data);
            this.openDialog = false;
            this.setSuccessMsg("Task Deleted!!");
          } else if (res.data.status == 0) {
            this.$developConsole(res, "task delete failed");
            this.setErrorMsg("Please try again!!");
          }
        })
        .catch((err) => {
          this.$developConsole(err);
          this.spinner = false;
          this.setErrorMsg("Please try again!!");
        });
    },
    openFile() {
      document.querySelector(".input_file").click();
    },
    onFileChange(e) {
      const file = e.target.files[0];
      this.fileUploaded = e.target.files[0];
      this.fileUrl = URL.createObjectURL(file);
      let { name, type, size } = this.fileUploaded;
      let obj = { name, type, size, url: this.fileUrl, loading: true };
      if (obj.type.split("/")[0] == "image") {
        this.uploadedFiles.unshift(obj);
      } else {
        this.otherDoc.unshift(obj);
      }

      this.handleFileUpload();
    },
    handleFileUpload() {
      let formData = new FormData();
      formData.append("document", this.fileUploaded);
      formData.append("project_id", this.$route.params.id);
      formData.append("document_type_id", 3);
      formData.append("item_id", this.selectedFeature.id);
      devUploadDocument(formData)
        .then((response) => {
          this.$developConsole(".......jkew wqer wer", response.data);
          if (response.data.status == 1) {
            this.setSuccessMsg("Document Uplaoded Successfully");
            this.$developConsole("Uploaded Doc", response.data);
          }
        })
        .catch((err) => {
          this.setErrorMsg("Please try again!!");
        });
    },
    deleteFeature() {
      this.spinner = true;
      let { id } = this.selectedFeature;
      devDeleteFeature(id)
        .then((res) => {
          if (res.data.status == 1) {
            this.$developConsole("Feature deleted", res.data);
            let newFeatures = this.features.filter((el) => el.id != id);
            this.updateProjectFeatures(newFeatures);
            this.openDialog = false;
            this.closeFeatureOverLay();
            this.setSuccessMsg("Feature Deleted");
            this.spinner = false;
          }
        })
        .catch((err) => {
          this.spinner = false;
          console.log(err);
        });
    },
  },
  computed: {
    ...mapState("devDashboard", ["selectedProjectDoc"]),
    tasks() {
      return this.selectedProjectDoc.tasks.filter(
        (task) => task.feature_id == this.selectedFeature.id
      );
    },
    features() {
      return this.selectedProjectDoc.features;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.drawer-wrapper {
  width: 1039px;
  /* margin-top: 147px; */
}
.toolbar {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 64px;
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  right: 0px;
}

.drawer-title {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  padding: 22px 0px;
}
.btn-wrapper {
  margin: 10px 0px 9px 0px;
}
.toolbar-action {
  display: flex;
}
.trash-task {
  margin-top: 20px;
  cursor: pointer;
  float:left;
}
/* Task list in feature drawer */
.feature-tasks-list {
}
.feature-task-div {
  background: #f7f7f8;
  border-radius: 4px;
  padding: 16px 13px 16px 16px;
  display: flex;
  margin-bottom: 8px;
}
.feature-task-content {
  width: 443px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.trash-icon {
  margin-left: 24px;
  cursor: pointer;
}
.input_file {
  display: block;
  visibility: hidden;
  width: 0;
  height: 0;
}
.uploading-wrapper {
  background: linear-gradient(
    168.53deg,
    rgba(27, 30, 34, 0.03) 75.69%,
    rgba(27, 30, 34, 0.72) 108.11%
  );
  /* opacity: 0.7; */
  border-radius: 4px;
  width: 247px;
  height: 196px;
}
.div-uploading {
  width: 181px;
  height: 196px;
  background: linear-gradient(
    89.02deg,
    #54cf94 -24.95%,
    rgba(84, 207, 148, 0) 94.84%
  );
  border-radius: 4px 0px 4px 4px;
  padding: 8px;
}
.pdf-uplaoding {
  padding: 8px;
  position: absolute;
  left: 0px;
  top: 0px;
  background: linear-gradient(
    90.01deg,
    #54cf94 -16.54%,
    rgba(84, 207, 148, 0) 94.9%
  );
  border-radius: 4px;
  width: 521px;
  height: 71px;
  height: 100%;
  opacity: 0.7;
}
.feature-title {
  font-size: 16px;
  line-height: 130%;
}
.feature-description {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
}
</style>
