var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "feature-drawer" },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { right: "", absolute: "", temporary: "" },
          model: {
            value: _vm.openDrawer,
            callback: function ($$v) {
              _vm.openDrawer = $$v
            },
            expression: "openDrawer",
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-wrapper" },
            [
              _c("div", { staticClass: "toolbar" }, [
                _c(
                  "div",
                  {
                    staticClass: "trash-task",
                    staticStyle: {
                      "padding-left": "20px",
                      "padding-right": "20px",
                    },
                    on: { click: _vm.toggleDrawer },
                  },
                  [
                    _c("img", {
                      staticStyle: { width: "25px" },
                      attrs: { src: "/img/dashboard/close-image.svg", alt: "" },
                    }),
                  ]
                ),
                _c("div", { staticClass: "drawer-title" }, [
                  _vm._v("Product Feature"),
                ]),
                _c("div", { staticClass: "toolbar-action" }, [
                  _c(
                    "div",
                    {
                      staticClass: "trash-task",
                      on: { click: _vm.handleDeleteFeature },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/img/dashboard/trash-icon.svg",
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "drawer-content" }, [
                _c("div", { staticClass: "drawer-left-box" }, [
                  _c("div", { staticClass: "drawer-top-content" }, [
                    _c("div", { staticClass: "drawer-feature-content" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedFeature.feature,
                            expression: "selectedFeature.feature",
                          },
                        ],
                        staticClass: "text-area-style feature-title",
                        attrs: { placeholder: "Add Feature" },
                        domProps: { value: _vm.selectedFeature.feature },
                        on: {
                          blur: function ($event) {
                            _vm.updateFeature($event, (_vm.type = "feature"))
                          },
                          keydown: function ($event) {
                            return _vm.keydownFeature($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.selectedFeature,
                              "feature",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "drawer-add-desc" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedFeature.description,
                            expression: "selectedFeature.description",
                          },
                        ],
                        staticClass: "text-area-style feature-description",
                        attrs: { placeholder: "Add description" },
                        domProps: { value: _vm.selectedFeature.description },
                        on: {
                          blur: function ($event) {
                            _vm.updateFeature(
                              $event,
                              (_vm.type = "description")
                            )
                          },
                          keydown: function ($event) {
                            return _vm.keydownFeature($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.selectedFeature,
                              "description",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "iteration-progress" }, [
                    _c("div", { staticClass: "drawer-itr-name" }, [
                      _c("span", [_vm._v("Iteration:")]),
                      _c("img", {
                        attrs: { src: "/img/dashboard/iteration1.svg" },
                      }),
                      _c("span", [
                        _vm._v("Iteration " + _vm._s(_vm.iterationNum + 1)),
                      ]),
                    ]),
                    _c("div", { staticClass: "progress-bar-div" }, [
                      _c("div", { staticClass: "progress-title" }, [
                        _vm._v("Progress:"),
                      ]),
                      _c("div", { staticClass: "progress-bar" }),
                      _c("div", { staticClass: "progress-percentage" }, [
                        _vm._v("0%"),
                      ]),
                    ]),
                  ]),
                  _c("div", { staticClass: "div-drawer-tasks" }, [
                    _c("div", { staticClass: "drawer-task-count" }, [
                      _c("img", {
                        attrs: { src: "/img/dashboard/sprint-icon.svg" },
                      }),
                      _c("span", [
                        _vm._v("Tasks (" + _vm._s(_vm.tasks.length) + ")"),
                      ]),
                    ]),
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.newTask,
                          expression: "newTask",
                        },
                      ],
                      staticClass: "text-area-style dark-textarea",
                      attrs: { placeholder: "+ Add Task" },
                      domProps: { value: _vm.newTask },
                      on: {
                        blur: function ($event) {
                          return _vm.handleAddTask($event)
                        },
                        keydown: function ($event) {
                          return _vm.keydownFeature($event)
                        },
                        input: function ($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.newTask = $event.target.value
                        },
                      },
                    }),
                    _c(
                      "div",
                      { staticClass: "feature-tasks-list" },
                      _vm._l(_vm.tasks, function (task, k) {
                        return _c(
                          "div",
                          { key: k, staticClass: "feature-task-div" },
                          [
                            _c("div", { staticClass: "feature-task-content" }, [
                              _vm._v(
                                "\n                  " +
                                  _vm._s(task.task) +
                                  "\n                "
                              ),
                            ]),
                            _c("div", { staticClass: "trash-icon" }, [
                              _c("img", {
                                attrs: {
                                  src: "/img/dashboard/delete-icon.svg",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.openDeleteModal(task.id)
                                  },
                                },
                              }),
                            ]),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]),
                _c("div", { staticClass: "drawer-right-box" }),
              ]),
              _vm.openDialog
                ? _c(
                    "DeleteModal",
                    _vm._b(
                      { attrs: { handleDelete: _vm.handleDeleteTask } },
                      "DeleteModal",
                      {
                        closeDialog: _vm.closeDialog,
                        deleteItem: _vm.deleteItem,
                        spinner: _vm.spinner,
                        deleteFeature: _vm.deleteFeature,
                      },
                      false
                    )
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }