var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.tasks.length == 0 && _vm.setOpen == false
        ? _c("div", { staticClass: "empty-roadmap" }, [
            _vm._m(0),
            _c("div", { staticClass: "empty-state" }, [
              _vm._v("There’s nothing here yet."),
            ]),
            _c("div", { staticClass: "sprint-planning" }, [
              _vm._v("Sprint Planning"),
            ]),
            _c("div", { staticClass: "sprint-info-list" }, [
              _vm._v(
                "\n      1. Add Tasks to Features/User Stories listed in the Iteration Backlog.\n    "
              ),
            ]),
            _c("div", { staticClass: "sprint-info-list" }, [
              _vm._v(
                "\n      2. Drag Tasks from Iteration Backlog and assign them to Sprints.\n    "
              ),
            ]),
            _c("div", { staticClass: "sprint-info-list" }, [
              _vm._v(
                "\n      3. Set task current stage ( See Default Workflow ) & assign\n      to teammate.\n    "
              ),
            ]),
            _c(
              "button",
              {
                staticClass: "grupa-blue-btn",
                staticStyle: { "margin-top": "48px" },
                on: {
                  click: function ($event) {
                    _vm.setOpen = true
                  },
                },
              },
              [_vm._v("\n      Get Started\n    ")]
            ),
          ])
        : _vm._e(),
      _vm.tasks.length > 0 || _vm.setOpen == true
        ? _c(
            "div",
            {
              staticClass: "sprint-container",
              on: { click: _vm.removePrompt },
            },
            [
              _c(
                "transition",
                { attrs: { name: "modal-fade", mode: "out-in" } },
                [
                  _vm.isIteration
                    ? _c(
                        "div",
                        { key: "1", staticClass: "selected-iteration" },
                        [
                          _c(
                            "div",
                            { staticClass: "selected-iteration-name" },
                            [
                              _c("div", { staticStyle: { display: "flex" } }, [
                                _c("div", [
                                  _c("img", {
                                    attrs: {
                                      src:
                                        "/img/dashboard/iteration" +
                                        _vm.currentIteration.it_number +
                                        ".svg",
                                    },
                                  }),
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "selected-itr-title" },
                                  [_vm._v("Iteration 1 - Must Have’s")]
                                ),
                              ]),
                              _c(
                                "div",
                                {
                                  staticStyle: { cursor: "pointer" },
                                  on: {
                                    click: function ($event) {
                                      _vm.isIteration = !_vm.isIteration
                                    },
                                  },
                                },
                                [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/dashboard/minimize-icon.svg",
                                    },
                                  }),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "VuePerfectScrollbar",
                            {
                              staticClass: "perfect-scroll-area",
                              attrs: { settings: _vm.settings },
                            },
                            [
                              _c("div", { staticClass: "itr-features-div" }, [
                                _c(
                                  "div",
                                  { staticClass: "feature-card-div" },
                                  [
                                    _c(
                                      "v-expansion-panel",
                                      {
                                        model: {
                                          value: _vm.currSection,
                                          callback: function ($$v) {
                                            _vm.currSection = $$v
                                          },
                                          expression: "currSection",
                                        },
                                      },
                                      _vm._l(
                                        _vm.features,
                                        function (feature, i) {
                                          return feature.iteration_id ==
                                            _vm.currentIteration.id
                                            ? _c(
                                                "v-expansion-panel-content",
                                                {
                                                  key: i,
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "actions",
                                                        fn: function () {
                                                          return [
                                                            i == _vm.currSection
                                                              ? _c("img", {
                                                                  attrs: {
                                                                    src: "/img/dashboard/chevron-open.svg",
                                                                  },
                                                                })
                                                              : _c("img", {
                                                                  attrs: {
                                                                    src: "/img/dashboard/chevron-close.svg",
                                                                  },
                                                                }),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                      {
                                                        key: "header",
                                                        fn: function () {
                                                          return [
                                                            _c("div", [
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "tem-feature-header",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        feature.feature
                                                                      ) +
                                                                      "\n                      "
                                                                  ),
                                                                ]
                                                              ),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "unassigned-task-div",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "\n                        " +
                                                                      _vm._s(
                                                                        _vm.calculateLinkedTask(
                                                                          feature.id
                                                                        )
                                                                      ) +
                                                                      " tasks assigned\n                      "
                                                                  ),
                                                                ]
                                                              ),
                                                            ]),
                                                          ]
                                                        },
                                                        proxy: true,
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    {
                                                      staticClass:
                                                        "feature-expand-content",
                                                    },
                                                    [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "add-new-feature-task",
                                                        },
                                                        [
                                                          _c("textarea", {
                                                            directives: [
                                                              {
                                                                name: "model",
                                                                rawName:
                                                                  "v-model",
                                                                value:
                                                                  _vm.newTasks[
                                                                    "task" +
                                                                      feature.id
                                                                  ],
                                                                expression:
                                                                  "newTasks[`task${feature.id}`]",
                                                              },
                                                            ],
                                                            staticClass:
                                                              "text-area-style",
                                                            style:
                                                              _vm.newTasks[
                                                                "task" +
                                                                  feature.id
                                                              ].length < 32
                                                                ? "height: 40px;"
                                                                : "",
                                                            attrs: {
                                                              placeholder:
                                                                "+ Add Task",
                                                              id: feature.id,
                                                            },
                                                            domProps: {
                                                              value:
                                                                _vm.newTasks[
                                                                  "task" +
                                                                    feature.id
                                                                ],
                                                            },
                                                            on: {
                                                              keydown:
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.keydownTask(
                                                                    $event
                                                                  )
                                                                },
                                                              blur: function (
                                                                $event
                                                              ) {
                                                                return _vm.createTaskToAFeature(
                                                                  $event,
                                                                  feature.id
                                                                )
                                                              },
                                                              input: function (
                                                                $event
                                                              ) {
                                                                if (
                                                                  $event.target
                                                                    .composing
                                                                ) {
                                                                  return
                                                                }
                                                                _vm.$set(
                                                                  _vm.newTasks,
                                                                  "task" +
                                                                    feature.id,
                                                                  $event.target
                                                                    .value
                                                                )
                                                              },
                                                            },
                                                          }),
                                                        ]
                                                      ),
                                                      _c(
                                                        "Container",
                                                        {
                                                          attrs: {
                                                            "group-name": "col",
                                                            "drag-class":
                                                              "card-ghost",
                                                            "drop-class":
                                                              "card-ghost-drop",
                                                          },
                                                        },
                                                        _vm._l(
                                                          _vm.tasks,
                                                          function (task, j) {
                                                            return task.feature_id ==
                                                              feature.id &&
                                                              task.sprint_id ==
                                                                null
                                                              ? _c(
                                                                  "Draggable",
                                                                  { key: j },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "unassigned-task-card",
                                                                        on: {
                                                                          mousedown:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.updateMove(
                                                                                $event,
                                                                                task
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "\n                          " +
                                                                            _vm._s(
                                                                              task.task
                                                                            ) +
                                                                            "\n                        "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e()
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              )
                                            : _vm._e()
                                        }
                                      ),
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c("div", { key: "2", staticClass: "iteartion-toggle" }, [
                        _c("img", {
                          staticStyle: { cursor: "pointer" },
                          attrs: { src: "/img/dashboard/maximize-icon.svg" },
                          on: {
                            click: function ($event) {
                              _vm.isIteration = !_vm.isIteration
                            },
                          },
                        }),
                      ]),
                ]
              ),
              _c(
                "VuePerfectScrollbar",
                {
                  staticClass: "perfect-scroll-area",
                  attrs: { settings: _vm.settings },
                },
                [
                  _c(
                    "div",
                    { staticClass: "sprint-wrapper" },
                    _vm._l(_vm.sprints, function (sprint, j) {
                      return sprint.iteration_id == _vm.currentIteration.id
                        ? _c(
                            "div",
                            { key: j, staticClass: "sprint-card-div" },
                            [
                              _c(
                                "div",
                                { staticClass: "sprint-info" },
                                [
                                  _c("div", { staticClass: "sprint-name" }, [
                                    _vm._v("Sprint " + _vm._s(sprint.sprint)),
                                  ]),
                                  _c(
                                    "div",
                                    { staticStyle: { display: "flex" } },
                                    [
                                      _c("div", [
                                        sprint.status < 1
                                          ? _c("img", {
                                              attrs: {
                                                src:
                                                  _vm.imgLink +
                                                  "clock-not-started.svg",
                                              },
                                            })
                                          : _vm._e(),
                                        sprint.status == 1
                                          ? _c("img", {
                                              attrs: {
                                                src:
                                                  _vm.imgLink +
                                                  "clock-ongoing.svg",
                                              },
                                            })
                                          : _vm._e(),
                                        sprint.status == 2
                                          ? _c("img", {
                                              attrs: {
                                                src:
                                                  _vm.imgLink +
                                                  "clock-done.svg",
                                              },
                                            })
                                          : _vm._e(),
                                      ]),
                                      sprint.status == 0
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "sprint-due-date",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openSprintStatus(
                                                    $event,
                                                    sprint.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                Not Started\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      sprint.status == 2
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "sprint-due-date",
                                              staticStyle: { color: "#1b1e22" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                Completed\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                      sprint.status == 1
                                        ? _c(
                                            "div",
                                            {
                                              staticClass: "sprint-due-date",
                                              style:
                                                sprint.status == 1
                                                  ? "color: #54CF94;"
                                                  : "",
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openSprintStatus(
                                                    $event,
                                                    sprint.id
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm
                                                      .$moment(
                                                        sprint.start_date
                                                      )
                                                      .format("Do MMM")
                                                  ) +
                                                  "-\n                " +
                                                  _vm._s(
                                                    _vm.addDaysFromDate(
                                                      sprint.start_date,
                                                      4
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                  _vm.currentSprint == sprint.id &&
                                  _vm.isSprintStatus
                                    ? _c(
                                        "SprintStatus",
                                        _vm._b(
                                          {},
                                          "SprintStatus",
                                          {
                                            sprint: sprint,
                                            stayTop: _vm.stayTop,
                                            compareTwoDates:
                                              _vm.compareTwoDates,
                                          },
                                          false
                                        )
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                              sprint.status < 2
                                ? _c(
                                    "div",
                                    { staticClass: "add-sprint-task" },
                                    [
                                      _c("textarea", {
                                        directives: [
                                          {
                                            name: "model",
                                            rawName: "v-model",
                                            value:
                                              _vm.newTask["task" + sprint.id],
                                            expression:
                                              "newTask[`task${sprint.id}`]",
                                          },
                                        ],
                                        staticClass: "text-area-style",
                                        style:
                                          _vm.newTask["task" + sprint.id]
                                            .length < 32
                                            ? "height: 40px;"
                                            : "",
                                        attrs: {
                                          placeholder:
                                            "+ Add Task or drag from iteration backlog",
                                          id: sprint.id,
                                        },
                                        domProps: {
                                          value:
                                            _vm.newTask["task" + sprint.id],
                                        },
                                        on: {
                                          keydown: function ($event) {
                                            return _vm.keydownTask($event)
                                          },
                                          blur: function ($event) {
                                            return _vm.handleCreateTask(
                                              $event,
                                              sprint.id
                                            )
                                          },
                                          input: function ($event) {
                                            if ($event.target.composing) {
                                              return
                                            }
                                            _vm.$set(
                                              _vm.newTask,
                                              "task" + sprint.id,
                                              $event.target.value
                                            )
                                          },
                                        },
                                      }),
                                    ]
                                  )
                                : _vm._e(),
                              sprint.status > 0
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "sprint-progress-div",
                                      class: {
                                        "completed-sprint-border":
                                          sprint.status == 2,
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "flex-progress" },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "sprint-progress-wrap",
                                            },
                                            [
                                              _c("div", {
                                                staticClass:
                                                  "sprint-progress-line",
                                                class: {
                                                  "completed-progress-line":
                                                    sprint.status == 2,
                                                },
                                                style:
                                                  sprint.status == 1
                                                    ? "width:" +
                                                      _vm.percentageTasksDone(
                                                        _vm.currentTasks
                                                      ) +
                                                      "%"
                                                    : "",
                                              }),
                                            ]
                                          ),
                                          _c(
                                            "div",
                                            { staticClass: "sprint-percent" },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    sprint.status == 1
                                                      ? parseInt(
                                                          _vm.percentageTasksDone(
                                                            _vm.currentTasks
                                                          )
                                                        )
                                                      : "100"
                                                  ) +
                                                  "%\n              "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ]
                                  )
                                : _c("div", {
                                    staticClass: "sprint-task-divider",
                                  }),
                              _c(
                                "VuePerfectScrollbar",
                                {
                                  staticClass: "perfect-scroll-area",
                                  attrs: { settings: _vm.settings },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "scroll-sprints-div",
                                      class: {
                                        "completed-sprint": sprint.status == 2,
                                        "ongoing-sprint": sprint.status == 1,
                                        "not-started-sprint":
                                          sprint.status == 0,
                                      },
                                    },
                                    [
                                      _c(
                                        "Container",
                                        {
                                          attrs: {
                                            "group-name": "col",
                                            "drag-class": "card-ghost",
                                            "drop-class": "card-ghost-drop",
                                            "drop-placeholder":
                                              _vm.dropPlaceholderOptions,
                                            "get-child-payload":
                                              _vm.getCardPayload(sprint.id),
                                          },
                                          on: {
                                            drop: function (e) {
                                              return _vm.onTaskDrop(
                                                sprint.id,
                                                e
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(_vm.tasks, function (task, k) {
                                          return _c("Draggable", { key: k }, [
                                            task.sprint_id == sprint.id
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass: "parent-card",
                                                    on: {
                                                      mousedown: function (
                                                        $event
                                                      ) {
                                                        _vm.movingTask = task.id
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "task-white-card",
                                                        on: {
                                                          click: function (
                                                            $event
                                                          ) {
                                                            return _vm.handleDrawer(
                                                              task,
                                                              sprint
                                                            )
                                                          },
                                                        },
                                                      },
                                                      [
                                                        _c("div", [
                                                          _vm._v(
                                                            _vm._s(task.task)
                                                          ),
                                                        ]),
                                                      ]
                                                    ),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "task-assig-icons",
                                                      },
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticStyle: {
                                                              display: "flex",
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              "v-menu",
                                                              {
                                                                staticClass:
                                                                  "user-story-menu",
                                                                attrs: {
                                                                  bottom: "",
                                                                  origin:
                                                                    "center center",
                                                                  transition:
                                                                    "scale-transition",
                                                                  "offset-y":
                                                                    "",
                                                                  "close-on-content-click": false,
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  "template",
                                                                  {
                                                                    slot: "activator",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "div",
                                                                      {
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.openLinkTask(
                                                                                task
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        task.feature_id ==
                                                                        null
                                                                          ? _c(
                                                                              "img",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    src: "/img/dashboard/task/unlinked-task.svg",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                        task.feature_id !=
                                                                        null
                                                                          ? _c(
                                                                              "img",
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    src: "/img/dashboard/task/link-task.svg",
                                                                                  },
                                                                              }
                                                                            )
                                                                          : _vm._e(),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm.isLinkPopup
                                                                  ? _c(
                                                                      "LinkTaskToFeature",
                                                                      {
                                                                        attrs: {
                                                                          linkSpinner:
                                                                            _vm.linkSpinner,
                                                                        },
                                                                        on: {
                                                                          handleLinkTask:
                                                                            _vm.handleLinkTask,
                                                                        },
                                                                      }
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              2
                                                            ),
                                                            _vm.user
                                                              .team_role ==
                                                            "Product Manager"
                                                              ? _c(
                                                                  "v-menu",
                                                                  {
                                                                    staticClass:
                                                                      "user-story-menu",
                                                                    attrs: {
                                                                      bottom:
                                                                        "",
                                                                      origin:
                                                                        "center center",
                                                                      transition:
                                                                        "scale-transition",
                                                                      "offset-y":
                                                                        "",
                                                                      "close-on-content-click": false,
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "template",
                                                                      {
                                                                        slot: "activator",
                                                                      },
                                                                      [
                                                                        _c(
                                                                          "div",
                                                                          {
                                                                            on: {
                                                                              click:
                                                                                function (
                                                                                  $event
                                                                                ) {
                                                                                  return _vm.handleAssignTask(
                                                                                    task
                                                                                  )
                                                                                },
                                                                            },
                                                                          },
                                                                          [
                                                                            task.teams &&
                                                                            task
                                                                              .teams
                                                                              .length >
                                                                              0
                                                                              ? _c(
                                                                                  "div",
                                                                                  {
                                                                                    staticClass:
                                                                                      "profile-icon",
                                                                                  },
                                                                                  _vm._l(
                                                                                    _vm.teamMembers,
                                                                                    function (
                                                                                      member,
                                                                                      i
                                                                                    ) {
                                                                                      return member.id ==
                                                                                        task
                                                                                          .teams[
                                                                                          task
                                                                                            .teams
                                                                                            .length -
                                                                                            1
                                                                                        ]
                                                                                          .dev_id
                                                                                        ? _c(
                                                                                            "v-avatar",
                                                                                            {
                                                                                              key: i,
                                                                                              staticClass:
                                                                                                "profile_bg",
                                                                                              style:
                                                                                                {
                                                                                                  backgroundImage:
                                                                                                    "url(" +
                                                                                                    (_vm.$imageUrl +
                                                                                                      member.profile_picture ||
                                                                                                      _vm.test) +
                                                                                                    ")",
                                                                                                },
                                                                                              attrs:
                                                                                                {
                                                                                                  size: 32,
                                                                                                  color:
                                                                                                    "grey lighten-4",
                                                                                                },
                                                                                            }
                                                                                          )
                                                                                        : _vm._e()
                                                                                    }
                                                                                  ),
                                                                                  1
                                                                                )
                                                                              : _c(
                                                                                  "img",
                                                                                  {
                                                                                    staticClass:
                                                                                      "unassign-icon",
                                                                                    attrs:
                                                                                      {
                                                                                        src: "/img/dashboard/task/unassign-task.svg",
                                                                                      },
                                                                                  }
                                                                                ),
                                                                          ]
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _vm.isAssignPopup
                                                                      ? _c(
                                                                          "AssignTaskPrompt",
                                                                          {
                                                                            on: {
                                                                              closePopup:
                                                                                _vm.closePopup,
                                                                            },
                                                                          }
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  2
                                                                )
                                                              : _c("div", [
                                                                  task.teams &&
                                                                  task.teams
                                                                    .length > 0
                                                                    ? _c(
                                                                        "div",
                                                                        {
                                                                          staticClass:
                                                                            "profile-icon",
                                                                          on: {
                                                                            click:
                                                                              _vm.handleRejectAction,
                                                                          },
                                                                        },
                                                                        _vm._l(
                                                                          _vm.teamMembers,
                                                                          function (
                                                                            member,
                                                                            i
                                                                          ) {
                                                                            return member.id ==
                                                                              task
                                                                                .teams[
                                                                                task
                                                                                  .teams
                                                                                  .length -
                                                                                  1
                                                                              ]
                                                                                .dev_id
                                                                              ? _c(
                                                                                  "v-avatar",
                                                                                  {
                                                                                    key: i,
                                                                                    staticClass:
                                                                                      "profile_bg",
                                                                                    style:
                                                                                      {
                                                                                        backgroundImage:
                                                                                          "url(" +
                                                                                          (_vm.$imageUrl +
                                                                                            member.profile_picture ||
                                                                                            _vm.test) +
                                                                                          ")",
                                                                                      },
                                                                                    attrs:
                                                                                      {
                                                                                        size: 32,
                                                                                        color:
                                                                                          "grey lighten-4",
                                                                                      },
                                                                                  }
                                                                                )
                                                                              : _vm._e()
                                                                          }
                                                                        ),
                                                                        1
                                                                      )
                                                                    : _c(
                                                                        "img",
                                                                        {
                                                                          staticClass:
                                                                            "unassign-icon",
                                                                          attrs:
                                                                            {
                                                                              src: "/img/dashboard/task/unassign-task.svg",
                                                                            },
                                                                        }
                                                                      ),
                                                                ]),
                                                          ],
                                                          1
                                                        ),
                                                        _c("div", [
                                                          task.task_status_id ==
                                                          5
                                                            ? _c("img", {
                                                                attrs: {
                                                                  src: "/img/sprint/task-completed.svg",
                                                                },
                                                              })
                                                            : _c("div", [
                                                                task.teams &&
                                                                task.teams
                                                                  .length > 0
                                                                  ? _c("img", {
                                                                      staticClass:
                                                                        "img-task-category",
                                                                      attrs: {
                                                                        src:
                                                                          _vm.imgLink1 +
                                                                          _vm.taskStage(
                                                                            task
                                                                              .teams[
                                                                              task
                                                                                .teams
                                                                                .length -
                                                                                1
                                                                            ]
                                                                              .task_type_id
                                                                          ) +
                                                                          ".svg",
                                                                      },
                                                                    })
                                                                  : _c("img", {
                                                                      attrs: {
                                                                        src: "/img/dashboard/task/task-status.svg",
                                                                      },
                                                                    }),
                                                              ]),
                                                        ]),
                                                      ]
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ])
                                        }),
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              ),
                            ],
                            1
                          )
                        : _vm._e()
                    }),
                    0
                  ),
                ]
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.openDialog
        ? _c(
            "DialogComponent",
            _vm._b(
              {
                attrs: { dialogTitle: "Development Workflow" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "dialog-footer",
                      fn: function () {
                        return [
                          _c(
                            "button",
                            {
                              staticClass: "grupa-grey-btn",
                              staticStyle: { "margin-right": "24px" },
                            },
                            [_vm._v("\n        Edit Workflow\n      ")]
                          ),
                          _c(
                            "button",
                            {
                              staticClass: "grupa-blue-btn",
                              on: {
                                click: function ($event) {
                                  _vm.setOpen = true
                                  _vm.openDialog = false
                                },
                              },
                            },
                            [_vm._v("\n        Okay, Got it\n      ")]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  1053882635
                ),
              },
              "DialogComponent",
              { closeDialog: _vm.closeDialog },
              false
            ),
            [
              _c("div", { attrs: { id: "main-children" } }, [
                _c("div", { staticClass: "sprint-guide" }, [
                  _vm._v(
                    "\n        Map out the stages of product development as fits your team and\n        teammates’ roles. Click below to edit the stages\n      "
                  ),
                ]),
                _c(
                  "div",
                  {
                    staticClass: "sprint-guide",
                    staticStyle: { "margin-top": "24px" },
                  },
                  [
                    _vm._v(
                      "\n        The default development workflow (shown below) starts with Product\n        Design, then proceeds to the Development/Coding phase and finally QA\n        Tests before Deployment.\n      "
                    ),
                  ]
                ),
                _c("div", { staticClass: "workflow-stage" }, [
                  _vm._v("Workflow stages"),
                ]),
                _c(
                  "div",
                  { staticClass: "workflow-progress" },
                  _vm._l(_vm.Workflow, function (stage, i) {
                    return _c(
                      "div",
                      { key: i, staticStyle: { display: "flex" } },
                      [
                        _c("div", [
                          _c("img", {
                            attrs: { src: _vm.imgLink + stage.img },
                          }),
                        ]),
                        _c("div", { staticClass: "stage-name" }, [
                          _vm._v(_vm._s(stage.name)),
                        ]),
                        i < 3
                          ? _c("div", { staticClass: "arrow-connect" }, [
                              _c("img", {
                                attrs: { src: _vm.imgLink + "link-arrow.svg" },
                              }),
                            ])
                          : _vm._e(),
                      ]
                    )
                  }),
                  0
                ),
              ]),
            ]
          )
        : _vm._e(),
      _c(
        "ViewSprintDialog",
        _vm._b(
          { ref: "drawer" },
          "ViewSprintDialog",
          {
            selectedTask: _vm.selectedTask,
            selectedSprint: _vm.selectedSprint,
            handleLinkTask: _vm.handleLinkTask,
            closeTaskOverLay: _vm.closeTaskOverLay,
          },
          false
        )
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("img", { attrs: { src: "/img/dashboard/empty-sprint.svg" } }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }