<template>
  <div @click="stayTop($event)" class="Sprint-status-div">
    <div class="div-sprint-info">
      <div class="sprint-name">Sprint {{ sprint.sprint }}</div>
      <div v-if="sprint.status == 0" class="grey-sprint-text">Not Started</div>
      <div
        v-if="sprint.status == 2"
        :style="
          compareTwoDates(sprint.due_date) < 0
            ? 'color: #DE9221;'
            : 'color: #54CF94;'
        "
        class="grey-sprint-text"
      >
        {{
          compareTwoDates(sprint.due_date) < 0
            ? `${Math.abs(compareTwoDates(sprint.due_date))} days overdue`
            : `+ ${compareTwoDates(sprint.due_date)} days left`
        }}
      </div>
      <!-- <div class="grey-sprint-text">Not Started</div> -->
    </div>
    <div class="sprint-goal">
      <div class="grey-sprint-text">SPRINT GOAL</div>
      <div class="sprint-goal-text">
        Design Sprint 2 Tasks and build back-end architecture.
      </div>
    </div>
    <div class="sprint-overdue-date">
      <div>
        <img src="/img/sprint/clock-not-started.svg" />
      </div>
      <div v-if="sprint.start_date == null">Timeline not set</div>
      <div v-if="sprint.start_date !== null">
        {{ $moment(sprint.start_date).format("Do MMM") }}-
        {{ addDaysFromDate(sprint.start_date, 4) }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    sprint: {
      required: true,
      type: Object,
    },
    stayTop: Function,
    compareTwoDates: Function,
  },
  components: {},

  data: () => ({}),
  created() {},
  methods: {
    addDaysFromDate(date, days) {
      let resultDate = this.$moment(date, "YYYY-MM-DD").add(days, "days");
      return resultDate.format("Do MMM YYYY");
    },
  },
  computed: {},
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.Sprint-status-div {
  position: absolute;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  box-shadow: 2px 5px 15px rgba(27, 30, 34, 0.07);
  border-radius: 4px;
  z-index: 60;
  width: 244px;
  right: 0px;
  top: 22px;
}
.div-sprint-info {
  display: flex;
  padding: 24px;
  justify-content: space-between;
}
.sprint-name {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.grey-sprint-text {
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
}
.sprint-goal {
  padding: 0px 24px;
  text-align: left;
}
.sprint-goal-text {
  margin-top: 2px;
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
}
.sprint-overdue-date {
  background: #f7f7f8;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  display: flex;
  justify-content: center;
  padding: 12px;
  margin: 24px 0px;
}
.sprint-overdue-date div:last-child {
  margin-top: 1px;
}
</style>
