var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "feature-drawer" },
    [
      _c(
        "v-navigation-drawer",
        {
          attrs: { right: "", absolute: "", temporary: "" },
          model: {
            value: _vm.openDrawer,
            callback: function ($$v) {
              _vm.openDrawer = $$v
            },
            expression: "openDrawer",
          },
        },
        [
          _c(
            "div",
            { staticClass: "drawer-wrapper" },
            [
              _c("div", { staticClass: "toolbar" }, [
                _c("div", { staticClass: "drawer-title" }, [
                  _vm._v("Sprint Task"),
                ]),
                _c("div", { staticClass: "toolbar-action" }, [
                  _c("div", { staticClass: "sprint-status-btn" }, [
                    _c("img", {
                      attrs: {
                        src: "/img/dashboard/alert-circle-icon.svg",
                        alt: "",
                      },
                    }),
                    _c("div", [_vm._v("Not Started")]),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "trash-task",
                      on: {
                        click: function ($event) {
                          _vm.openDialog = true
                        },
                      },
                    },
                    [
                      _c("img", {
                        attrs: {
                          src: "/img/dashboard/trash-icon.svg",
                          alt: "",
                        },
                      }),
                    ]
                  ),
                ]),
              ]),
              _c("div", { staticClass: "drawer-content" }, [
                _c("div", { staticClass: "drawer-left-box" }, [
                  _c("div", { staticClass: "drawer-top-content" }, [
                    _c("div", { staticClass: "drawer-feature-content" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedTask.task,
                            expression: "selectedTask.task",
                          },
                        ],
                        staticClass: "text-area-style task-title",
                        domProps: { value: _vm.selectedTask.task },
                        on: {
                          blur: function ($event) {
                            return _vm.handleUpdateTask($event, "task")
                          },
                          keydown: function ($event) {
                            return _vm.keydownTask($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.selectedTask,
                              "task",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                    _c("div", { staticClass: "drawer-add-desc" }, [
                      _c("textarea", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedTask.description,
                            expression: "selectedTask.description",
                          },
                        ],
                        staticClass: "text-area-style task-description",
                        attrs: { placeholder: "Add description" },
                        domProps: { value: _vm.selectedTask.description },
                        on: {
                          blur: function ($event) {
                            return _vm.handleUpdateTask($event, "description")
                          },
                          keydown: function ($event) {
                            return _vm.keydownTask($event)
                          },
                          input: function ($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.selectedTask,
                              "description",
                              $event.target.value
                            )
                          },
                        },
                      }),
                    ]),
                  ]),
                  _c("div", { staticClass: "drawer-sprint-info" }, [
                    _c("div", { staticClass: "sprint-name" }, [
                      _vm._v("\n              Sprint: "),
                      _c("span", [
                        _vm._v("Sprint " + _vm._s(_vm.selectedSprint.sprint)),
                      ]),
                    ]),
                    _c("div", { staticClass: "task-stage-div" }, [
                      _c("div", { staticClass: "task-stage-text" }, [
                        _vm._v("Task Stage:"),
                      ]),
                      _vm.selectedTask.teams &&
                      _vm.selectedTask.teams.length > 0
                        ? _c(
                            "div",
                            {
                              staticClass: "task-stage-btn",
                              class: {
                                "in-development":
                                  _vm.selectedTask.teams[
                                    _vm.selectedTask.teams.length - 1
                                  ].task_type_id == 1,
                                "in-design":
                                  _vm.selectedTask.teams[
                                    _vm.selectedTask.teams.length - 1
                                  ].task_type_id == 2,
                                "in-testing":
                                  _vm.selectedTask.teams[
                                    _vm.selectedTask.teams.length - 1
                                  ].task_type_id == 3,
                              },
                            },
                            [
                              _vm.selectedTask.teams[
                                _vm.selectedTask.teams.length - 1
                              ].task_type_id == 2
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.imgLink + "workflow1.svg",
                                    },
                                  })
                                : _vm._e(),
                              _vm.selectedTask.teams[
                                _vm.selectedTask.teams.length - 1
                              ].task_type_id == 3
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.imgLink + "workflow3.svg",
                                    },
                                  })
                                : _vm._e(),
                              _vm.selectedTask.teams[
                                _vm.selectedTask.teams.length - 1
                              ].task_type_id == 1
                                ? _c("img", {
                                    attrs: {
                                      src: _vm.imgLink + "workflow2.svg",
                                    },
                                  })
                                : _vm._e(),
                              _c("div", [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.taskStage(
                                        _vm.selectedTask.teams[
                                          _vm.selectedTask.teams.length - 1
                                        ].task_type_id
                                      )
                                    ) +
                                    "\n                "
                                ),
                              ]),
                            ]
                          )
                        : _c("div", { staticClass: "task-stage-btn" }, [
                            _c("img", {
                              attrs: {
                                src: "/img/sprint/question-mark.svg",
                                alt: "",
                              },
                            }),
                            _c("div", [_vm._v("Not Assigned")]),
                          ]),
                    ]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "link-to-feature" },
                    [
                      _c("div", { staticClass: "grey-header-text" }, [
                        _c("img", {
                          attrs: { src: "/img/sprint/link-to.svg", alt: "" },
                        }),
                        _c("div", [_vm._v("LINKED FEATURE OR USER STORY")]),
                      ]),
                      _c(
                        "v-menu",
                        {
                          staticClass: "user-story-menu",
                          attrs: {
                            bottom: "",
                            origin: "center center",
                            transition: "scale-transition",
                            "offset-y": "",
                            "close-on-content-click": false,
                            disabled: _vm.selectedTask.feature_id !== null,
                          },
                        },
                        [
                          _c("template", { slot: "activator" }, [
                            _c("div", { staticClass: "select-user-story" }, [
                              _vm.selectedTask.feature_id === null
                                ? _c("div", [
                                    _vm._v(
                                      "\n                    Select feature or user story\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                              _vm.selectedTask.feature_id !== null
                                ? _c("div", [
                                    _vm._v(
                                      "\n                    " +
                                        _vm._s(
                                          _vm.findFeature(
                                            _vm.selectedTask.feature_id
                                          )
                                        ) +
                                        "\n                  "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]),
                          _c(
                            "div",
                            [
                              _c("div", { staticClass: "prompt-header" }, [
                                _vm._v(
                                  "\n                  Link Task to Feature or User Story\n                  "
                                ),
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.spinner,
                                      expression: "spinner",
                                    },
                                  ],
                                  staticClass: "button-spinner",
                                  staticStyle: { float: "right" },
                                  attrs: { src: "/img/lightbox/preloader.gif" },
                                }),
                              ]),
                              _vm._l(_vm.features, function (feature, i) {
                                return feature.feature !== " "
                                  ? _c(
                                      "div",
                                      {
                                        key: i,
                                        staticClass: "user-story-list",
                                        on: {
                                          mouseover: function ($event) {
                                            _vm.selected = true
                                            _vm.currentHover = feature.id
                                          },
                                          mouseleave: function ($event) {
                                            _vm.selected = false
                                            _vm.currentHover = ""
                                          },
                                          click: function ($event) {
                                            return _vm.handleLinkTaskToFeature(
                                              feature.id
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(feature.feature) +
                                            "\n                  "
                                        ),
                                        _vm.selected &&
                                        _vm.currentHover == feature.id
                                          ? _c("img", {
                                              staticStyle: { float: "right" },
                                              attrs: {
                                                src: "/img/dashboard/task/checkmark.svg",
                                              },
                                            })
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e()
                              }),
                            ],
                            2
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                  _c(
                    "div",
                    { staticClass: "assign-task-to" },
                    [
                      _c("div", { staticClass: "grey-header-text" }, [
                        _c("img", {
                          attrs: { src: "/img/sprint/person-add.svg", alt: "" },
                        }),
                        _c("div", [_vm._v("ASSIGNed to")]),
                      ]),
                      _c(
                        "v-menu",
                        {
                          staticClass: "team-members-menu",
                          attrs: {
                            bottom: "",
                            origin: "center center",
                            transition: "scale-transition",
                            "offset-y": "",
                            "close-on-content-click": false,
                          },
                        },
                        [
                          _c("template", { slot: "activator" }, [
                            _vm.selectedTask.teams &&
                            _vm.selectedTask.teams.length > 0
                              ? _c(
                                  "div",
                                  { staticClass: "select-member" },
                                  [
                                    _c("v-avatar", {
                                      staticClass: "profile_bg",
                                      style: {
                                        backgroundImage:
                                          "url(" +
                                          (_vm.$imageUrl +
                                            _vm.devInfo(
                                              _vm.selectedTask.teams[
                                                _vm.selectedTask.teams.length -
                                                  1
                                              ].dev_id
                                            ).profile_picture || _vm.test) +
                                          ")",
                                      },
                                      attrs: {
                                        size: 32,
                                        color: "grey lighten-4",
                                      },
                                    }),
                                    _c(
                                      "div",
                                      { staticStyle: { "margin-top": "14px" } },
                                      [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.devInfo(
                                                _vm.selectedTask.teams[
                                                  _vm.selectedTask.teams
                                                    .length - 1
                                                ].dev_id
                                              ).name
                                            ) +
                                            "\n                  "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _c("div", { staticClass: "select-member" }, [
                                  _c("img", {
                                    attrs: {
                                      src: "/img/dashboard/task/unassign-task.svg",
                                      alt: "",
                                    },
                                  }),
                                  _c("div", [_vm._v("Select teammate")]),
                                ]),
                          ]),
                          _c(
                            "div",
                            [
                              _c("div", { staticClass: "prompt-header" }, [
                                _vm._v(
                                  "\n                  Assign Task\n                  "
                                ),
                                _c("img", {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: _vm.spinner,
                                      expression: "spinner",
                                    },
                                  ],
                                  staticClass: "button-spinner",
                                  staticStyle: { float: "right" },
                                  attrs: { src: "/img/lightbox/preloader.gif" },
                                }),
                              ]),
                              _vm._l(_vm.teamMembers, function (member, i) {
                                return member.name
                                  ? _c(
                                      "div",
                                      {
                                        key: i,
                                        staticClass: "members-list-task",
                                        on: {
                                          mouseover: function ($event) {
                                            _vm.selected = true
                                            _vm.currentHover = member.id
                                          },
                                          mouseleave: function ($event) {
                                            _vm.selected = false
                                            _vm.currentHover = ""
                                          },
                                          click: function ($event) {
                                            return _vm.handleAssignTask(member)
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticStyle: { display: "flex" } },
                                          [
                                            member.team_role ==
                                            "Product Designer"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _c("div", [
                                                      _c("img", {
                                                        attrs: {
                                                          src: "/img/dashboard/task/design.svg",
                                                        },
                                                      }),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "member-task",
                                                      },
                                                      [_vm._v("Design")]
                                                    ),
                                                  ]
                                                )
                                              : member.team_role ==
                                                  "QA Engineer" ||
                                                member.team_role == "QA/QC"
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _c("div", [
                                                      _c("img", {
                                                        attrs: {
                                                          src: "/img/dashboard/task/testing.svg",
                                                        },
                                                      }),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "member-task",
                                                      },
                                                      [_vm._v("QA Test")]
                                                    ),
                                                  ]
                                                )
                                              : _c(
                                                  "div",
                                                  {
                                                    staticStyle: {
                                                      display: "flex",
                                                    },
                                                  },
                                                  [
                                                    _c("div", [
                                                      _c("img", {
                                                        attrs: {
                                                          src: "/img/dashboard/task/development.svg",
                                                        },
                                                      }),
                                                    ]),
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "member-task",
                                                      },
                                                      [_vm._v("Development")]
                                                    ),
                                                  ]
                                                ),
                                            _vm._v(
                                              "\n                    -\n                    "
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "profile-icon" },
                                              [
                                                _c("v-avatar", {
                                                  staticClass: "profile_bg",
                                                  style: {
                                                    backgroundImage:
                                                      "url(" +
                                                      (_vm.$imageUrl +
                                                        member.profile_picture ||
                                                        _vm.test) +
                                                      ")",
                                                  },
                                                  attrs: {
                                                    size: 24,
                                                    color: "grey lighten-4",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _c(
                                              "div",
                                              { staticClass: "member-task" },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.shortenName(
                                                        member.name
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm.selected &&
                                        _vm.currentHover == member.id
                                          ? _c("div", [
                                              _c("img", {
                                                attrs: {
                                                  src: "/img/dashboard/task/checkmark.svg",
                                                },
                                              }),
                                            ])
                                          : _vm._e(),
                                      ]
                                    )
                                  : _vm._e()
                              }),
                            ],
                            2
                          ),
                        ],
                        2
                      ),
                    ],
                    1
                  ),
                ]),
                _c("div", { staticClass: "drawer-right-box" }),
              ]),
              _vm.openDialog
                ? _c(
                    "DeleteModal",
                    _vm._b(
                      {
                        attrs: {
                          deleteItem: "Task",
                          handleDelete: _vm.handleDeleteTask,
                        },
                      },
                      "DeleteModal",
                      { closeDialog: _vm.closeDialog, spinner: _vm.spinner },
                      false
                    )
                  )
                : _vm._e(),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }