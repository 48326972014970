var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "DialogComponent",
    {
      attrs: {
        dialogTitle: "Delete Iteration",
        closeDialog: _vm.closeDeleteItr,
      },
      scopedSlots: _vm._u([
        {
          key: "dialog-footer",
          fn: function () {
            return [
              _c(
                "button",
                {
                  staticClass: "grupa-white-btn",
                  staticStyle: { "margin-right": "24px" },
                  on: { click: _vm.closeDeleteItr },
                },
                [_vm._v("\n      Cancel\n    ")]
              ),
              _c(
                "button",
                {
                  staticClass: "grupa-disabled-red-btn",
                  class: _vm.enableBtn ? "grupa-red-btn" : null,
                  attrs: { disabled: !_vm.enableBtn },
                  on: {
                    click: function ($event) {
                      return _vm.handleDeleteIteration(_vm.deleteIteration.id)
                    },
                  },
                },
                [
                  _vm._v("\n      Delete\n      "),
                  _c("img", {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: _vm.delSpinner,
                        expression: "delSpinner",
                      },
                    ],
                    staticClass: "button-spinner",
                    staticStyle: { "margin-left": "20px" },
                    attrs: { src: "/img/lightbox/preloader.gif" },
                  }),
                ]
              ),
            ]
          },
          proxy: true,
        },
      ]),
    },
    [
      _c("div", { attrs: { id: "main-children" } }, [
        _c("div", { staticClass: "delete-itr-msg" }, [
          _vm._v("\n      You are about to delete\n      "),
          _c("span", { staticStyle: { "font-weight": "bold" } }, [
            _vm._v(
              "\n        Iteration " + _vm._s(_vm.deleteIteration.it_number)
            ),
          ]),
          _vm._v(
            "\n      and all features, tasks and comments associated with Iteraion\n      " +
              _vm._s(_vm.deleteIteration.it_number) +
              "\n    "
          ),
        ]),
        _c("div", { staticClass: "delete-warning-msg" }, [
          _vm._v(
            "\n      This action is irreversible, tick the boxes below to proceed\n    "
          ),
        ]),
        _c("div", { staticClass: "delete-child-list" }, [
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  _vm.isCheckFeatures = !_vm.isCheckFeatures
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src:
                    "/img/onboarding/client/" +
                    (_vm.isCheckFeatures ? "checked" : "unchecked") +
                    ".svg",
                  alt: "",
                },
              }),
            ]
          ),
          _c("div", [
            _vm._v("Delete Iteration " + _vm._s(_vm.deleteIteration.it_number)),
          ]),
        ]),
        _c("div", { staticClass: "delete-child-list" }, [
          _c(
            "div",
            {
              on: {
                click: function ($event) {
                  _vm.isCheckTasks = !_vm.isCheckTasks
                },
              },
            },
            [
              _c("img", {
                attrs: {
                  src:
                    "/img/onboarding/client/" +
                    (_vm.isCheckTasks ? "checked" : "unchecked") +
                    ".svg",
                  alt: "",
                },
              }),
            ]
          ),
          _c("div", [_vm._v("Delete all its features, tasks and comments")]),
        ]),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }