<template>
  <div class="feature-drawer">
    <v-navigation-drawer v-model="openDrawer" right absolute temporary>
      <div class="drawer-wrapper">
        <div class="toolbar">
          <div class="drawer-title">Sprint Task</div>
          <div class="toolbar-action">
            <div class="sprint-status-btn">
              <img src="/img/dashboard/alert-circle-icon.svg" alt="" />
              <div>Not Started</div>
            </div>
            <div class="trash-task" @click="openDialog = true">
              <img src="/img/dashboard/trash-icon.svg" alt="" />
            </div>
          </div>
        </div>
        <div class="drawer-content">
          <div class="drawer-left-box">
            <div class="drawer-top-content">
              <div class="drawer-feature-content">
                <textarea
                  v-model="selectedTask.task"
                  @blur="handleUpdateTask($event, 'task')"
                  @keydown="keydownTask($event)"
                  class="text-area-style task-title"
                >
                </textarea>
              </div>
              <div class="drawer-add-desc">
                <textarea
                  placeholder="Add description"
                  v-model="selectedTask.description"
                  class="text-area-style task-description"
                  @blur="handleUpdateTask($event, 'description')"
                  @keydown="keydownTask($event)"
                >
                </textarea>
              </div>
            </div>
            <div class="drawer-sprint-info">
              <div class="sprint-name">
                Sprint: <span>Sprint {{ selectedSprint.sprint }}</span>
              </div>
              <div class="task-stage-div">
                <div class="task-stage-text">Task Stage:</div>
                <div
                  v-if="selectedTask.teams && selectedTask.teams.length > 0"
                  class="task-stage-btn"
                  :class="{
                    'in-development':
                      selectedTask.teams[selectedTask.teams.length - 1]
                        .task_type_id == 1,
                    'in-design':
                      selectedTask.teams[selectedTask.teams.length - 1]
                        .task_type_id == 2,
                    'in-testing':
                      selectedTask.teams[selectedTask.teams.length - 1]
                        .task_type_id == 3,
                  }"
                >
                  <img
                    v-if="
                      selectedTask.teams[selectedTask.teams.length - 1]
                        .task_type_id == 2
                    "
                    :src="`${imgLink}workflow1.svg`"
                  />
                  <img
                    v-if="
                      selectedTask.teams[selectedTask.teams.length - 1]
                        .task_type_id == 3
                    "
                    :src="`${imgLink}workflow3.svg`"
                  />
                  <img
                    v-if="
                      selectedTask.teams[selectedTask.teams.length - 1]
                        .task_type_id == 1
                    "
                    :src="`${imgLink}workflow2.svg`"
                  />
                  <div>
                    {{
                      taskStage(
                        selectedTask.teams[selectedTask.teams.length - 1]
                          .task_type_id
                      )
                    }}
                  </div>
                </div>
                <div v-else class="task-stage-btn">
                  <img src="/img/sprint/question-mark.svg" alt="" />
                  <div>Not Assigned</div>
                </div>
              </div>
            </div>
            <div class="link-to-feature">
              <div class="grey-header-text">
                <img src="/img/sprint/link-to.svg" alt="" />
                <div>LINKED FEATURE OR USER STORY</div>
              </div>
              <!-- <div> -->
              <v-menu
                class="user-story-menu"
                bottom
                origin="center center"
                transition="scale-transition"
                offset-y
                :close-on-content-click="false"
                :disabled="selectedTask.feature_id !== null"
              >
                <template slot="activator">
                  <div class="select-user-story">
                    <div v-if="selectedTask.feature_id === null">
                      Select feature or user story
                    </div>
                    <div v-if="selectedTask.feature_id !== null">
                      {{ findFeature(selectedTask.feature_id) }}
                    </div>
                  </div>
                </template>
                <div>
                  <div class="prompt-header">
                    Link Task to Feature or User Story
                    <img
                      src="/img/lightbox/preloader.gif"
                      style="float: right"
                      class="button-spinner"
                      v-show="spinner"
                    />
                  </div>
                  <div
                    class="user-story-list"
                    v-for="(feature, i) in features"
                    :key="i"
                    v-if="feature.feature !== ' '"
                    @mouseover="
                      selected = true;
                      currentHover = feature.id;
                    "
                    @mouseleave="
                      selected = false;
                      currentHover = '';
                    "
                    @click="handleLinkTaskToFeature(feature.id)"
                  >
                    {{ feature.feature }}
                    <img
                      v-if="selected && currentHover == feature.id"
                      style="float: right"
                      src="/img/dashboard/task/checkmark.svg"
                    />
                  </div>
                </div>
              </v-menu>
              <!-- </div> -->
            </div>
            <div class="assign-task-to">
              <div class="grey-header-text">
                <img src="/img/sprint/person-add.svg" alt="" />
                <div>ASSIGNed to</div>
              </div>
              <!-- Select Menu -->
              <v-menu
                class="team-members-menu"
                bottom
                origin="center center"
                transition="scale-transition"
                offset-y
                :close-on-content-click="false"
              >
                <!-- :disabled="selectedTask.teams && selectedTask.teams.length > 0" -->
                <template slot="activator">
                  <div
                    v-if="selectedTask.teams && selectedTask.teams.length > 0"
                    class="select-member"
                  >
                    <v-avatar
                      :size="32"
                      color="grey lighten-4"
                      class="profile_bg"
                      :style="{
                        backgroundImage: `url(${
                          $imageUrl +
                            devInfo(
                              selectedTask.teams[selectedTask.teams.length - 1]
                                .dev_id
                            ).profile_picture || test
                        })`,
                      }"
                    ></v-avatar>
                    <div style="margin-top: 14px">
                      {{
                        devInfo(
                          selectedTask.teams[selectedTask.teams.length - 1]
                            .dev_id
                        ).name
                      }}
                    </div>
                  </div>
                  <div v-else class="select-member">
                    <img src="/img/dashboard/task/unassign-task.svg" alt="" />
                    <div>Select teammate</div>
                  </div>
                </template>
                <div>
                  <div class="prompt-header">
                    Assign Task
                    <img
                      src="/img/lightbox/preloader.gif"
                      style="float: right"
                      class="button-spinner"
                      v-show="spinner"
                    />
                  </div>
                  <div
                    class="members-list-task"
                    v-for="(member, i) in teamMembers"
                    :key="i"
                    @mouseover="
                      selected = true;
                      currentHover = member.id;
                    "
                    @mouseleave="
                      selected = false;
                      currentHover = '';
                    "
                    v-if="member.name"
                    @click="handleAssignTask(member)"
                  >
                    <div style="display: flex">
                      <!-- team_role: "Product Designer" -->
                      <div
                        v-if="member.team_role == 'Product Designer'"
                        style="display: flex"
                      >
                        <div>
                          <img src="/img/dashboard/task/design.svg" />
                        </div>
                        <div class="member-task">Design</div>
                      </div>
                      <!-- team_role: "QA Engineer" -->
                      <div
                        v-else-if="
                          member.team_role == 'QA Engineer' ||
                          member.team_role == 'QA/QC'
                        "
                        style="display: flex"
                      >
                        <div>
                          <img src="/img/dashboard/task/testing.svg" />
                        </div>
                        <div class="member-task">QA Test</div>
                      </div>
                      <!-- team_role: Others -->
                      <div v-else style="display: flex">
                        <div>
                          <img src="/img/dashboard/task/development.svg" />
                        </div>
                        <div class="member-task">Development</div>
                      </div>
                      -
                      <div class="profile-icon">
                        <v-avatar
                          :size="24"
                          color="grey lighten-4"
                          class="profile_bg"
                          :style="{
                            backgroundImage: `url(${
                              $imageUrl + member.profile_picture || test
                            })`,
                          }"
                        ></v-avatar>
                      </div>
                      <div class="member-task">
                        {{ shortenName(member.name) }}
                      </div>
                    </div>
                    <div v-if="selected && currentHover == member.id">
                      <img src="/img/dashboard/task/checkmark.svg" />
                    </div>
                  </div>
                </div>
              </v-menu>
              <!--  -->
            </div>
          </div>
          <div class="drawer-right-box">
            <!-- <CommentAndActivity /> -->
          </div>
        </div>
        <DeleteModal
          v-if="openDialog"
          deleteItem="Task"
          v-bind="{ closeDialog, spinner }"
          v-bind:handleDelete="handleDeleteTask"
        />
      </div>
    </v-navigation-drawer>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex";
// import CommentAndActivity from "@/components/comments/commentAndActvity";
import { devUpdateTask, devAssignTask, devDeleteTask } from "@/api/team";
import shortName from "@/mixins/shortName";
import computeTask from "@/mixins/computeTask";
import appMixin from "@/mixins/appMixin";

import DeleteModal from "@/components/Modals/deleteModal";
export default {
  mixins: [shortName, computeTask, appMixin],
  props: {
    selectedTask: Object,
    selectedSprint: Object,
    handleLinkTask: Function,
    closeTaskOverLay: Function,
  },
  components: {
    // CommentAndActivity,
    DeleteModal,
  },

  data: () => ({
    openDrawer: false,
    openDialog: false,
    profile: "/img/onboarding/company/profile.jpeg",
    currentHover: "",
    selected: false,
    spinner: false,
    taskAssigned: false,
    imgLink: "/img/sprint/",
  }),

  created() {},
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("devDashboard", [
      "updateProjectTask",
      "updateSelectedProjectDoc",
    ]),
    toggleDrawer() {
      this.openDrawer = !this.openDrawer;
    },
    closeDrawer() {
      this.openDrawer = false;
    },
    handleUpdateTask(event, type) {
      let el = event.target;
      el.classList.add("textShine");
      let { id, task, sprint_id, description } = this.selectedTask;
      let data = {
        task_id: id,
        sprint_id,
      };
      if (task !== "" && type == "task") {
        data.task = task;
        this.updateTask(el, data);
      } else if (description !== "" && type == "description") {
        data.description = description;
        this.updateTask(el, data);
      }
    },
    updateTask(event, data) {
      devUpdateTask(data)
        .then((res) => {
          event.classList.remove("textShine");
          if (res.data.status == 1) {
            this.setSuccessMsg("Task Updated!!");
          }
        })
        .catch((err) => {
          this.setErrorMsg("Please try again!!");
          event.classList.remove("textShine");
        });
    },
    keydownTask(event) {
      if (event.key != "Enter") {
        return;
      }
      event.target.blur();
    },
    async handleAssignTask(developer) {
      this.spinner = true;
      let task_id = this.selectedTask.id;
      let { team_role, id: dev_id } = developer;
      let task_type_id = this.taskCategory(team_role);
      let payload = {
        task_type_id,
        task_id,
        dev_id,
        date_timezone: this.currentDate(),
      };
      let taskList = JSON.parse(JSON.stringify(this.tasks));
      if (this.spinner == true) {
        await devAssignTask(payload)
          .then((res) => {
            if (res.data) {
              this.spinner = false;
              this.taskAssigned = true;
              let taskObj = res.data.data.task;
              this.setSuccessMsg("Task Assigned!!");
              let taskIndex = taskList.findIndex((task) => task.id == task_id);
              taskList[taskIndex] = taskObj;
              this.updateProjectTask(taskList);
              // this.removePrompt();
            }
          })
          .catch((err) => {
            this.spinner = false;
            this.taskAssigned = true;
            this.setErrorMsg("Please try again!!");
            this.$developConsole(err);
          });
      }
    },
    devInfo(id) {
      return this.teamMembers.find((dev) => dev.id == id);
    },
    findFeature(featureId) {
      let featureObj = this.features.find((feature) => feature.id == featureId);
      if (featureObj) {
        return featureObj.feature;
      }
    },
    async handleLinkTaskToFeature(featureId) {
      this.spinner = true;
      let task_id = this.selectedTask.id;
      let data = {
        feature_id: featureId,
        task_id,
      };
      this.handleLinkTask(data);
    },
    closeDialog() {
      this.openDialog = false;
    },
    handleDeleteTask() {
      let taskId = this.selectedTask.id;
      this.spinner = true;
      devDeleteTask(taskId)
        .then((res) => {
          if (res.data.status == 1) {
            this.spinner = false;
            this.updateSelectedProjectDoc(res.data.data);
            this.openDialog = false;
            this.setSuccessMsg("Task Deleted!!");
            this.closeTaskOverLay();
          } else if (res.data.status == 0) {
            this.spinner = false;
            this.$developConsole(res, "task delete failed");
            this.setErrorMsg("Please try again!!");
          }
        })
        .catch((err) => {
          this.$developConsole(err);
          this.spinner = false;
          this.setErrorMsg("Please try again!!");
        });
    },
  },
  computed: {
    ...mapState("devDashboard", [
      "selectedProjectDoc",
      "selectedProject",
      "teamInfo",
    ]),
    teamMembers() {
      return this.teamInfo.team.members;
    },
    currentIteration() {
      return this.selectedProjectDoc.iterations.find(
        (itr) => itr.id == this.selectedProject.current_iteration
      );
    },
    features() {
      return this.selectedProjectDoc.features.filter(
        (ftr) => ftr.iteration_id == this.currentIteration.id
      );
    },
    tasks() {
      return this.selectedProjectDoc.tasks;
    },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}
.drawer-wrapper {
  width: 1039px;
  /* margin-top: 147px; */
}
.toolbar {
  background: #ffffff;
  box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 64px;
  padding: 0px 24px;
  display: flex;
  justify-content: space-between;
  position: sticky;
  top: 0px;
  right: 0px;
}
.toolbar-action {
  display: flex;
}
.sprint-status-btn {
  display: flex;
  padding: 9px 16px;
  background: #ffffff;
  border: 1px solid #e4e5e7;
  box-sizing: border-box;
  border-radius: 4px;
  font-size: 12px;
  line-height: 140%;
  color: #979da5;
  margin-top: 14px;
  width: 129px;
  margin-right: 40px;
  height: 35px;
}
.in-development {
  background: rgba(84, 189, 212, 0.15) !important;
  color: #54bdd4 !important;
}
.in-design {
  background: rgba(212, 84, 199, 0.1) !important;
  color: #d454c7 !important;
}
.in-testing {
  background: rgba(222, 146, 33, 0.1) !important;
  color: #de9221 !important;
}
.sprint-status-btn img {
  margin-right: 8px;
}
.drawer-title {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  padding: 22px 0px;
}
.drawer-sprint-info {
  padding: 24px;
  border-top: 1px solid #f7f7f8;
  border-bottom: 1px solid #f7f7f8;
}
.assign-task-to,
.link-to-feature {
  padding: 24px;
  border-bottom: 1px solid #f7f7f8;
}
.sprint-name {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.sprint-name span {
  color: #1b1e22;
}
.task-stage-div {
  display: flex;
  margin-top: 24px;
}
.task-stage-text {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 2px;
}
.task-stage-btn {
  background: #f7f7f8;
  border-radius: 4px;
  padding: 6px 14px;
  font-size: 12px;
  line-height: 130%;
  color: #979da5;
  display: flex;
  margin-left: 8px;
  text-transform: capitalize;
}
.task-stage-btn img {
  height: 12px;
  width: 12px;
}
.task-stage-btn div {
  margin-left: 6px;
  font-size: 12px;
  line-height: 130%;
}
.grey-header-text {
  font-size: 14px;
  line-height: 130%;
  text-transform: uppercase;
  color: #979da5;
  display: flex;
}
.grey-header-text img {
  margin-right: 4px;
}
.select-user-story,
.select-member {
  background: #f7f7f8;
  border-radius: 4px;
  padding: 9px 16px;
  display: flex;
  margin-top: 24px;
  width: 100%;
}
.select-user-story {
  padding: 16px;
}
.select-user-story div {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.select-member div {
  margin-top: 7px;
  margin-left: 8px;
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
}
.trash-task {
  margin-top: 20px;
  cursor: pointer;
}
.members-list-task {
  padding: 9px 24px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.members-list-task:hover {
  background: #f7f7f8;
}
.member-task {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
  margin: 4px 16px 0px 8px;
}
.members-list-task .profile-icon {
  margin-left: 16px;
}
.prompt-header {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  padding: 24px 24px 12px;
  text-align: left;
}
.user-story-list {
  padding: 12px 24px;
  cursor: pointer;
  text-align: left;
}
.user-story-list:hover {
  background: #f7f7f8;
}
.task-title {
  font-size: 16px;
  line-height: 130%;
}
.task-description {
  font-size: 14px;
  line-height: 130%;
  color: #53585f;
}
</style>

<style>
.v-menu--inline.user-story-menu,
.v-menu--inline.team-members-menu {
  width: 100%;
  background: white;
}
.v-menu__content {
  background: white;
}
</style>