<template>
  <div>
    <Loader v-if="project == null" />
    <div v-else class="page-container">
      <div class="itr-navigation">
        <div class="itr-toggle">
          <div
            @click="activeTab = 'iteration'"
            class="iteration-btn"
            :class="{ ' active-btn': activeTab == 'iteration' }"
          >
            Iterations
          </div>
          <div
            @click="activeTab = 'sprint'"
            class="sprint-btn"
            :class="{ ' active-btn': activeTab == 'sprint' }"
          >
            Sprints
          </div>
        </div>
        <div class="action-div">
          <div class="itr-time-line" v-if="activeTab == 'iteration'">
            <img src="/img/sprint/question-mark-circle.svg" alt="" />
            <span
              >Timeline:
              {{ iterations.length * product.iteration_cycle }}
              weeks</span
            >
          </div>

          <div
            v-if="
              project.features.length > 0 &&
              product.workspace_progress_level < 7
            "
            class="share-roadmap-btn"
            :class="{
              'disbled-share-btn': project.features.length == 0,
              'disbled-share-btn': product.share_roadmap == true,
            }"
            @click="handleRoadmapModal"
          >
            <img src="/img/dashboard/mark-icon.svg" style="margin-right: 8px" />

            {{ product.share_roadmap ? "Shared" : "Share Roadmap" }}
          </div>

          {{
/*             IterationSprintStatus.iteration_id + "  - " + currentIteration.id
 */          }}

          <div
            v-if="
              currentSprint
                ? currentSprint.start_date != '' ||
                  currentSprint.start_date != null
                : null
            "
          >
            <div class="sprint-name">
              <div class="sprint-no">Sprint {{ currentSprint.sprint }}</div>
              <div><img src="/img/sprint/clock-ongoing.svg" /></div>
              <span
                style="
                  color: rgb(84, 207, 148);
                  margin-left: 5px;
                  font-size: 11px; ;
                "
              >
                {{ sprintDate }}</span
              >
            </div>
          </div>

          <div
            @click="routeToBacklog"
            v-if="
              currentSprint
                ? currentSprint.start_date != '' ||
                  currentSprint.start_date != null
                : null
            "
            style="margin-left: 30px"
            class="share-roadmap-btn btn btn-primary"
          >
            <img src="/img/dashboard/mark-icon.svg" style="margin-right: 2px" />
            Update Backlog
          </div>
          <!-- 
            v-if="
              product.workspace_progress_level == 7 &&
              (currentSprint
                ? currentSprint.start_date == '' ||
                  currentSprint.start_date == null
                : true)
            "
           -->

          <div
            class="share-roadmap-btn btn btn-primary"
            :class="{ 'disbled-share-btn': project.iterations.length == 0 }"
            @click="isOpenDialog = true"
            v-if="
              product.workspace_progress_level == 7 &&
              (currentSprint
                ? currentSprint.start_date == '' ||
                  currentSprint.start_date == null
                : true)
            "
          >
            <img src="/img/dashboard/mark-icon.svg" style="margin-right: 8px" />
            Start Sprint 1
          </div>
        </div>
      </div>
      <div class="div-wrapper">
        <ProductIterations v-if="activeTab == 'iteration'" />
        <ProductSprints v-else />
      </div>
    </div>
    <DialogComponent
      dialogTitle="Share Roadmap"
      v-if="openDialog"
      v-bind="{ closeDialog }"
    >
      <div v-if="noSprint" id="main-children">
        <div class="share-roadmap-msg">
          You are about to share the product roadmap with the client. Ensure
          that everything is as discussed and agreed during the design sprint
          sessions.
        </div>
        <div class="share-roadmap-prompt">Do you wish to continue?</div>
      </div>
      <div v-else id="main-children">
        <div class="danger-img-div">
          <img src="/img/dashboard/danger-icon.svg" alt="" />
          <div>One more step!</div>
        </div>
        <div class="next-step-div">
          You have to plan the Sprints for Iteration 1 before you can share the
          roadmap.
        </div>
        <div class="next-step-div">
          Toggle to the Sprints board to start planning
        </div>
      </div>
      <template v-slot:dialog-footer>
        <div v-if="noSprint">
          <button
            @click="closeDialog"
            class="grupa-grey-btn"
            style="margin-right: 24px"
          >
            No, Go back
          </button>
          <button class="grupa-blue-btn" @click="handleShareRoadmap">
            Yes, Share
            <img
              src="/img/lightbox/preloader.gif"
              style="margin-left: 20px"
              class="button-spinner"
              v-show="spinner"
            />
          </button>
        </div>
        <div v-else class="center-btn">
          <button @click="closeDialog" class="grupa-white-blue-btn">
            Okay, Got it
          </button>
        </div>
      </template>
    </DialogComponent>
    <AlertSuccess v-if="successMsg" time="7" />
    <AlertError v-if="errorMsg" time="7" />
    <StartSprint
      v-if="isOpenDialog"
      v-bind:form="sprintForm"
      v-bind="{ handleStartSprint, start_spinner }"
      v-bind:closeDialog="closeStartModal"
    />
  </div>
</template>

<script>
import ProductIterations from "./productIterations";
import ProductSprints from "./productSprints";
import Loader from "@/components/general/centerLoader";
import AlertSuccess from "@/components/alertSuccess";
import AlertError from "@/components/alertError";
import StartSprint from "./startSprint";
import { devShareRoadmap } from "@/api";
import {
  formatDayMonth,
  businessDaysDiffInDays,
  addDaysFromDate,
} from "@/utils/dateHelpers";
// import { getDevProject } from "@/api/team";
import { getDevProject, fetchProject, devStartSprintOne } from "@/api";
import { mapActions, mapState } from "vuex";
import DialogComponent from "@/components/Modals/dialogComponent";

export default {
  props: {},
  components: {
    ProductIterations,
    ProductSprints,
    Loader,
    DialogComponent,
    AlertSuccess,
    AlertError,
    StartSprint,
  },
  data: () => ({
    openDialog: false,
    project: null,
    product: null,
    isOpenDialog: false,
    activeTab: "iteration",
    isOpenDialog: false,
    sprintForm: {
      startMenu: false,
      start_date: "",
      end_date: "",
    },
    start_spinner: false,
    noSprint: true,
    spinner: false,
  }),
  watch: {
    "sprintForm.start_date": {
      handler: function (newValue) {
        let new_date = this.$moment(newValue, "YYYY-MM-DD").add(5, "days");
        this.sprintForm.end_date = new_date.format("YYYY-MM-DD");
      },
    },
  },
  created() {
    this.fetchProject();
  },
  methods: {
    ...mapActions("alertMsg", ["setSuccessMsg", "setErrorMsg"]),
    ...mapActions("devDashboard", [
      "updateSelectedProjectDoc",
      "updateSelectedProject",
    ]),

    ...mapState("clientDashboard", ["clientProject", "clientProjectDoc"]),

    percentageTasksDone(work) {
      let done = work.filter((task) => task.task_status_id == 5);
      let completed = done.length;
      let totalWork = work.length;
      let ratio = completed / totalWork;
      return Math.round(ratio * 100);
    },
    routeToBacklog() {
      //alert('r')

      this.$router.push({
        name: "dev_backlog",
        params: { id: this.$route.params.id },
      });
    },
    async fetchProject() {
      await getDevProject(this.$route.params.id).then((res) => {
        this.project = res.data.data;
        this.product = res.data.data.project;
        this.updateSelectedProjectDoc(res.data.data);
        if (this.product.workspace_progress_level > 7) {
          this.activeTab = "sprint";
        }
      });
    },
    handleStartSprint() {
      this.start_spinner = true;
      const d = new Date();
      const hr = d.getHours();
      const mi = d.getMinutes();
      const se = d.getSeconds() < 10 ? "0" + d.getSeconds() : d.getSeconds();

      let timmer = ` ${hr}:${mi}:${se} `;
      let payload = {
        start_date: this.sprintForm.start_date, //+ timmer,
        progress_level: 8,
        project_id: this.$route.params.id,
      };

      devStartSprintOne(payload)
        .then((res) => {
          this.start_spinner = false;
          if (res.data.status == 1) {
            this.isOpenDialog = false;
            this.product = res.data.data.project;
            this.updateSelectedProject(res.data.data.project);
            this.setSuccessMsg("Sprint 1 Started!!");
          }
        })
        .catch((err) => {
          this.start_spinner = false;
        });
    },
    closeStartModal() {
      this.isOpenDialog = false;
    },
    closeDialog() {
      this.openDialog = false;
    },
    handleRoadmapModal() {
      if (this.product.share_roadmap == false) {
        this.noSprint = true;
        if (this.project.features.length > 0) {
          this.openDialog = true;
        }
      }
    },
    handleShareRoadmap() {
      if (this.tasks.length == 0) {
        this.noSprint = false;
      } else if (this.tasks.length > 0) {
        this.shareRoadMap();
      }
    },
    async shareRoadMap() {
      this.spinner = true;
      let project_id = this.$route.params.id;
      await devShareRoadmap(project_id)
        .then((resp) => {
          this.spinner = false;
          this.openDialog = false;
          this.setSuccessMsg("Roadmap Shared!!");
          setTimeout(() => {
            this.$router.push({
              name: "dev_workspace",
              params: { id: project_id },
            });
          }, 2000);
        })
        .catch((err) => {
          this.spinner = false;
        });
    },
  },
  computed: {
    ...mapState("alertMsg", ["successMsg", "errorMsg"]),
    ...mapState("devDashboard", [
      "selectedProjectDoc",
      "selectedProject",
      "teamInfo",
    ]),
    tasks() {
      return this.selectedProjectDoc.tasks.filter(
        (task) => task.sprint_id == this.currentSprint.id
      );
    },
    sprintDate() {
      let { start_date } = this.currentSprint;
      if (start_date !== null) {
        return `${this.$moment(start_date).format("Do MMM")} -
          ${addDaysFromDate(start_date, 4)}`;
      } else {
        return "Not Started";
      }
    },
    tasks() {
      return this.selectedProjectDoc.tasks;
      // return this.selectedProjectDoc.tasks.filter(
      //   (task) => task.iteration_id == this.currentIteration.id
      // );
    },
    IterationSprintStatus() {
      //console.log(this.selectedProjectDoc);
      return this.selectedProjectDoc.sprints.find(
        (sprint, i) => sprint.status == 0
      );
    },
    iterations() {
      return this.selectedProjectDoc.iterations;
    },
    projectDoc() {
      return this.clientProjectDoc.documents;
    },
    currentIteration() {
      return this.projectDoc.iterations.find(
        (itr) => itr.id == this.clientProject.current_iteration
      );
    },
    currentSprint() {
      return this.selectedProjectDoc.sprints.find(
        (sprint) => sprint.status == 1
      );
    },
    // project() {
    //   return this.selectedProjectDoc;
    // },
  },
};
</script>
<style scoped>
* {
  font-family: Moderat;
  font-style: normal;
  font-weight: normal;
}

.itr-no,
.sprint-no {
  border-right: 1px solid #e4e5e7;
  padding-right: 8px;
  font-size: 11px;
}

.bolder-header {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  margin-top: 4px;
}

.sprint-name img {
  margin-left: 8px;
}

.sprint-name {
  font-size: 14px;
  line-height: 130%;
  color: #979da5;
  margin-top: 12px;
  display: flex;
}
.page-container {
  padding: 0px;
}

.share-roadmap-msg {
  font-size: 14px;
  line-height: 130%;
  color: #1b1e22;
  width: 356px;
  text-align: left;
}
.share-roadmap-prompt {
  font-size: 14px;
  line-height: 130%;
  color: #6f7680;
  margin-top: 24px;
  text-align: left;
}
.danger-img-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-content: center;
  width: 356px;
  align-items: center;
}

.danger-img-div img {
  width: 69px;
  height: 69px;
}
.next-step-div,
.danger-img-div div {
  font-size: 14px;
  line-height: 130%;
  text-align: center;
  color: #1b1e22;
  margin-top: 16px;
}
.next-step-div {
  margin-top: 24px;
  text-align: left;
  width: 356px;
}
.center-btn {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
</style>
