var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.project == null
        ? _c("Loader")
        : _c("div", { staticClass: "page-container" }, [
            _c("div", { staticClass: "itr-navigation" }, [
              _c("div", { staticClass: "itr-toggle" }, [
                _c(
                  "div",
                  {
                    staticClass: "iteration-btn",
                    class: { " active-btn": _vm.activeTab == "iteration" },
                    on: {
                      click: function ($event) {
                        _vm.activeTab = "iteration"
                      },
                    },
                  },
                  [_vm._v("\n            Iterations\n          ")]
                ),
                _c(
                  "div",
                  {
                    staticClass: "sprint-btn",
                    class: { " active-btn": _vm.activeTab == "sprint" },
                    on: {
                      click: function ($event) {
                        _vm.activeTab = "sprint"
                      },
                    },
                  },
                  [_vm._v("\n            Sprints\n          ")]
                ),
              ]),
              _c("div", { staticClass: "action-div" }, [
                _vm.activeTab == "iteration"
                  ? _c("div", { staticClass: "itr-time-line" }, [
                      _c("img", {
                        attrs: {
                          src: "/img/sprint/question-mark-circle.svg",
                          alt: "",
                        },
                      }),
                      _c("span", [
                        _vm._v(
                          "Timeline:\n              " +
                            _vm._s(
                              _vm.iterations.length *
                                _vm.product.iteration_cycle
                            ) +
                            "\n              weeks"
                        ),
                      ]),
                    ])
                  : _vm._e(),
                _vm.project.features.length > 0 &&
                _vm.product.workspace_progress_level < 7
                  ? _c(
                      "div",
                      {
                        staticClass: "share-roadmap-btn",
                        class: {
                          "disbled-share-btn": _vm.project.features.length == 0,
                          "disbled-share-btn":
                            _vm.product.share_roadmap == true,
                        },
                        on: { click: _vm.handleRoadmapModal },
                      },
                      [
                        _c("img", {
                          staticStyle: { "margin-right": "8px" },
                          attrs: { src: "/img/dashboard/mark-icon.svg" },
                        }),
                        _vm._v(
                          "\n\n            " +
                            _vm._s(
                              _vm.product.share_roadmap
                                ? "Shared"
                                : "Share Roadmap"
                            ) +
                            "\n          "
                        ),
                      ]
                    )
                  : _vm._e(),
                _vm._v(
                  "\n\n          " +
                    _vm._s(/*             IterationSprintStatus.iteration_id + "  - " + currentIteration.id
                     */) +
                    "\n\n          "
                ),
                (
                  _vm.currentSprint
                    ? _vm.currentSprint.start_date != "" ||
                      _vm.currentSprint.start_date != null
                    : null
                )
                  ? _c("div", [
                      _c("div", { staticClass: "sprint-name" }, [
                        _c("div", { staticClass: "sprint-no" }, [
                          _vm._v("Sprint " + _vm._s(_vm.currentSprint.sprint)),
                        ]),
                        _c("div", [
                          _c("img", {
                            attrs: { src: "/img/sprint/clock-ongoing.svg" },
                          }),
                        ]),
                        _c(
                          "span",
                          {
                            staticStyle: {
                              color: "rgb(84, 207, 148)",
                              "margin-left": "5px",
                              "font-size": "11px",
                            },
                          },
                          [
                            _vm._v(
                              "\n                " + _vm._s(_vm.sprintDate)
                            ),
                          ]
                        ),
                      ]),
                    ])
                  : _vm._e(),
                (
                  _vm.currentSprint
                    ? _vm.currentSprint.start_date != "" ||
                      _vm.currentSprint.start_date != null
                    : null
                )
                  ? _c(
                      "div",
                      {
                        staticClass: "share-roadmap-btn btn btn-primary",
                        staticStyle: { "margin-left": "30px" },
                        on: { click: _vm.routeToBacklog },
                      },
                      [
                        _c("img", {
                          staticStyle: { "margin-right": "2px" },
                          attrs: { src: "/img/dashboard/mark-icon.svg" },
                        }),
                        _vm._v("\n            Update Backlog\n          "),
                      ]
                    )
                  : _vm._e(),
                _vm.product.workspace_progress_level == 7 &&
                (_vm.currentSprint
                  ? _vm.currentSprint.start_date == "" ||
                    _vm.currentSprint.start_date == null
                  : true)
                  ? _c(
                      "div",
                      {
                        staticClass: "share-roadmap-btn btn btn-primary",
                        class: {
                          "disbled-share-btn":
                            _vm.project.iterations.length == 0,
                        },
                        on: {
                          click: function ($event) {
                            _vm.isOpenDialog = true
                          },
                        },
                      },
                      [
                        _c("img", {
                          staticStyle: { "margin-right": "8px" },
                          attrs: { src: "/img/dashboard/mark-icon.svg" },
                        }),
                        _vm._v("\n            Start Sprint 1\n          "),
                      ]
                    )
                  : _vm._e(),
              ]),
            ]),
            _c(
              "div",
              { staticClass: "div-wrapper" },
              [
                _vm.activeTab == "iteration"
                  ? _c("ProductIterations")
                  : _c("ProductSprints"),
              ],
              1
            ),
          ]),
      _vm.openDialog
        ? _c(
            "DialogComponent",
            _vm._b(
              {
                attrs: { dialogTitle: "Share Roadmap" },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "dialog-footer",
                      fn: function () {
                        return [
                          _vm.noSprint
                            ? _c("div", [
                                _c(
                                  "button",
                                  {
                                    staticClass: "grupa-grey-btn",
                                    staticStyle: { "margin-right": "24px" },
                                    on: { click: _vm.closeDialog },
                                  },
                                  [
                                    _vm._v(
                                      "\n            No, Go back\n          "
                                    ),
                                  ]
                                ),
                                _c(
                                  "button",
                                  {
                                    staticClass: "grupa-blue-btn",
                                    on: { click: _vm.handleShareRoadmap },
                                  },
                                  [
                                    _vm._v(
                                      "\n            Yes, Share\n            "
                                    ),
                                    _c("img", {
                                      directives: [
                                        {
                                          name: "show",
                                          rawName: "v-show",
                                          value: _vm.spinner,
                                          expression: "spinner",
                                        },
                                      ],
                                      staticClass: "button-spinner",
                                      staticStyle: { "margin-left": "20px" },
                                      attrs: {
                                        src: "/img/lightbox/preloader.gif",
                                      },
                                    }),
                                  ]
                                ),
                              ])
                            : _c("div", { staticClass: "center-btn" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "grupa-white-blue-btn",
                                    on: { click: _vm.closeDialog },
                                  },
                                  [
                                    _vm._v(
                                      "\n            Okay, Got it\n          "
                                    ),
                                  ]
                                ),
                              ]),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  2444746655
                ),
              },
              "DialogComponent",
              { closeDialog: _vm.closeDialog },
              false
            ),
            [
              _vm.noSprint
                ? _c("div", { attrs: { id: "main-children" } }, [
                    _c("div", { staticClass: "share-roadmap-msg" }, [
                      _vm._v(
                        "\n          You are about to share the product roadmap with the client. Ensure\n          that everything is as discussed and agreed during the design sprint\n          sessions.\n        "
                      ),
                    ]),
                    _c("div", { staticClass: "share-roadmap-prompt" }, [
                      _vm._v("Do you wish to continue?"),
                    ]),
                  ])
                : _c("div", { attrs: { id: "main-children" } }, [
                    _c("div", { staticClass: "danger-img-div" }, [
                      _c("img", {
                        attrs: {
                          src: "/img/dashboard/danger-icon.svg",
                          alt: "",
                        },
                      }),
                      _c("div", [_vm._v("One more step!")]),
                    ]),
                    _c("div", { staticClass: "next-step-div" }, [
                      _vm._v(
                        "\n          You have to plan the Sprints for Iteration 1 before you can share the\n          roadmap.\n        "
                      ),
                    ]),
                    _c("div", { staticClass: "next-step-div" }, [
                      _vm._v(
                        "\n          Toggle to the Sprints board to start planning\n        "
                      ),
                    ]),
                  ]),
            ]
          )
        : _vm._e(),
      _vm.successMsg ? _c("AlertSuccess", { attrs: { time: "7" } }) : _vm._e(),
      _vm.errorMsg ? _c("AlertError", { attrs: { time: "7" } }) : _vm._e(),
      _vm.isOpenDialog
        ? _c(
            "StartSprint",
            _vm._b(
              {
                attrs: {
                  form: _vm.sprintForm,
                  closeDialog: _vm.closeStartModal,
                },
              },
              "StartSprint",
              {
                handleStartSprint: _vm.handleStartSprint,
                start_spinner: _vm.start_spinner,
              },
              false
            )
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }